import React from 'react'
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import videoimg from '../assets/videoimg.png'
import SwiperComponent from './home/Swiper';
import Swipercomponents from './Swiperherohome'

const Home_hero = () => {
  const data = [
    { id: 1, img: videoimg },
    { id: 2, img: videoimg },
    { id: 3, img: videoimg },
    { id: 4, img: videoimg },
  ]
  return (
    <div className='herorel'>
      <div className='backgroundimghomehero'>
        <div className='generatebiggerrents d-flex flex-column justify-content-center text-center'>
          <div className=' generatorwidht'>
            <h1 className='homeheroh1' style={{ letterSpacing: '-2', fontWeight: '600' }}>Generate Bigger Rents From <span>Co-living</span></h1>
            {/* <p className='homeheroP m-0 p-0'>Let's dive in together and explore the world of co-living</p> */}
            {/* <p className='homeheroP' style={{ fontWeight: '500', fontSize: '26px' }}>Ready? Let's get started!</p> */}
            <div className='d-flex justify-content-center flexer' style={{ gap: '20px' }}>
              <Link to='/pageSelecter'><Button className='calculatorbtn'>Click here to learn more</Button></Link>
              {/* <Button type="submit" className='readaboutusherobtn py-3 '>Read More About Us</Button> */}
            </div>
          </div>
        


        <div className='swiperposab mt-4 mb-5'>
          {/* <SwiperComponent data={data} /> */}
          <Swipercomponents />
        </div>
      </div>
    </div>
    </div>
  )
}

export default Home_hero
