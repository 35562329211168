import React from 'react'
import { Container } from 'react-bootstrap'

const BgwhiteQuestion = (props) => {
  return (
    <div>
      <Container>
        <div className='questionanswerstolearn my-4'>
          <h2 className='py-2 mb-5' style={{ borderBottom: '3px solid #1B71AB', width: 'fit-content' }}>{props.question}</h2>
          <p>
            <p className='m-0'> {props.answerp1}</p>
            <p className='m-0'>{props.answerp2}</p>
          </p>
        </div>
      </Container>
    </div>
  )
}

export default BgwhiteQuestion
