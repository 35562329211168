import React, { useState } from 'react'

const LoanComponent = (props) => {
  const [loanValue,setLoanValue] = useState('')
  const [interest,setInterest] = useState('')
  const [term,setTerm] = useState('')

  return (
    <div>
      


<div className="loantovalue mt-5">
  <div className="mt-3">
    <h5>
      <label>Loan to Value</label>
    </h5>
    <input
      type="text"
      className="w-100 px-2 py-3 priceinput"
      value={loanValue}
      onChange={(e)=>{
        setLoanValue(e.target.value)
        
      }}
      onkeyup={props.values(loanValue,interest,term)}
    />
  </div>
  <div className="mt-3">
    <h5>
      <label>Interest Rate</label>
    </h5>
    <input
      type="text"
      className="w-100 px-2 py-3 priceinput"
      value={interest}
      onChange={(e)=>{
        setInterest(e.target.value)
        props.values(loanValue,interest,term)
      }}
    />
  </div>
  <div className="mt-3">
    <h5>
      <label>Term</label>
    </h5>
    <input
      type="text"
      className="w-100 px-2 py-3 priceinput"
      value={term}
      onChange={(e)=>{
        setTerm(e.target.value)
        props.values(loanValue,interest,term)
      }}
    />
  </div>
  <div
    className="py-4 px-4 d-flex justify-content-between mt-4 bottomborder"
    style={{ background: "#e2edf3" }}

  >
    <div>

      <h5 className="m-0 p-0" style={{ fontWeight: "600" }}>
        Loan Amount
      </h5>
    </div>
    <div>
      <h5 className="m-0 p-0" style={{ fontWeight: "600" }}>
        $85,000,00
      </h5>
    </div>
  </div>
  <div></div>
</div>
</div>
    
  )
}

export default LoanComponent




// <div className="secondloan">

// <div className="mt-5 d-flex justify-content-between rowtocol">
//   <h1
//     className="text-start"
//     style={{
//       fontWeight: "600",
//       letterSpacing: "-2",
//       alignSelf: "center",
//     }}
//   >
//     <label>Second Loan</label>
//   </h1>
//   {/* <Link to="/Padsplit/Blended"> */}
//     <Button
//       className="anotherloan2 px-md-5 py-md-3 text-end"
//       style={{ fontSize: "large" }}
//       onClick={() => {
//         $('.anotherloan2').css('display', 'none');
//         $('.thirdloan').css('display', 'inherit');
//       }}
//     >
   
//       Take another loan
//     </Button>
//   {/* </Link> */}
// </div>
// <div className="loantovalue mt-5">
//   <div className="mt-3">
//     <h5>
//       <label>Loan to Value</label>
//     </h5>
//     <input
//       type="text"
//       className="w-100 px-2 py-3 priceinput"
//       value="50%"
//     />
//   </div>
//   <div className="mt-3">
//     <h5>
//       <label>Interest Rate</label>
//     </h5>
//     <input
//       type="text"
//       className="w-100 px-2 py-3 priceinput"
//       value="8,75 %"
//     />
//   </div>
//   <div className="mt-3">
//     <h5>
//       <label>Term</label>
//     </h5>
//     <input
//       type="text"
//       className="w-100 px-2 py-3 priceinput"
//       value="360"
//     />
//   </div>
//   <div
//     className="py-4 px-4 d-flex justify-content-between mt-4 bottomborder"
//     style={{ background: "#e2edf3" }}

//   >
//     <div>

//       <h5 className="m-0 p-0" style={{ fontWeight: "600" }}>
//         Loan Amount
//       </h5>
//     </div>
//     <div>
//       <h5 className="m-0 p-0" style={{ fontWeight: "600" }}>
//         $85,000,00
//       </h5>
//     </div>
//   </div>
//   <div></div>
// </div>
// </div>
// <div className="thirdloan">

// <div className="mt-5 d-flex justify-content-between rowtocol">
//   <h1
//     className="text-start"
//     style={{
//       fontWeight: "600",
//       letterSpacing: "-2",
//       alignSelf: "center",
//     }}
//   >
//     <label>Third Loan</label>
//   </h1>

// </div>
// <div className="loantovalue mt-5">
//   <div className="mt-3">
//     <h5>
//       <label>Loan to Value</label>
//     </h5>
//     <input
//       type="text"
//       className="w-100 px-2 py-3 priceinput"
//       value="50%"
//     />
//   </div>
//   <div className="mt-3">
//     <h5>
//       <label>Interest Rate</label>
//     </h5>
//     <input
//       type="text"
//       className="w-100 px-2 py-3 priceinput"
//       value="8,75 %"
//     />
//   </div>
//   <div className="mt-3">
//     <h5>
//       <label>Term</label>
//     </h5>
//     <input
//       type="text"
//       className="w-100 px-2 py-3 priceinput"
//       value="360"
//     />
//   </div>
//   <div
//     className="py-4 px-4 d-flex justify-content-between mt-4 bottomborder"
//     style={{ background: "#e2edf3" }}

//   >
//     <div>

//       <h5 className="m-0 p-0" style={{ fontWeight: "600" }}>
//         Loan Amount
//       </h5>
//     </div>
//     <div>
//       <h5 className="m-0 p-0" style={{ fontWeight: "600" }}>
//         $85,000,00
//       </h5>
//     </div>
//   </div>
//   <div></div>
// </div>
// </div>