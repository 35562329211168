import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
const Buyerlearn = () => {
    return (
        <div>
            <Row>
                <Col md='6'>
                    <Link className='learn' to='/LearnHow'>Learn How it Works?</Link>
                </Col>
                <Col md='6' className='d-flex flex-md-row flex-column justify-content-md-end justify-content-center mt-md-0 mt-5'>
                    <Button className='learnbtn py-3 mt-3 mt-md-0 mx-sm-2'>
                        For Wholesaler
                    </Button>
                    <Button className='learnbtn py-3 mt-3 mt-md-0 mx-sm-2'>
                        Add Loan
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default Buyerlearn
