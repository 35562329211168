import React from 'react'
import Navbar from '../components/Navbar'
import Buyerlearn from '../components/Buyerlearn'
import Padsplit from '../components/Padsplit'

const Buyer = () => {
    return (
        <div>
            <Navbar bigbtn="Contact Us" bigbtnlink="" />
            <div className='mt-5 px-5'>
                <Buyerlearn />
            </div>
            
                <Padsplit/>
            
        </div>
    )
}

export default Buyer
