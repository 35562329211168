import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Learnhowitwork from './pages/Learnhowitwork'
import Buyer from './pages/Buyer';
import Blended from './components/Blended';
import SelectPage from './pages/SelectPage';
import LearnHow from './pages/LearnHow';
import LoanState from './context/LoanState';
import GoalSeekComponent from './pages/GoolSeeks';

function App() {
  return (
    <div className="App">
      <LoanState>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Padsplit" element={<Buyer />} />
          <Route path="/Goolseeks" element={<GoalSeekComponent />} />

          <Route path="/LearnHow/learn" element={<Learnhowitwork />} />
          <Route path="/Padsplit/Blended" element={<Blended />} />
          <Route path='/pageSelecter' element={<SelectPage />} />
          <Route path='/LearnHow' element={<LearnHow />} />
        </Routes>
      </BrowserRouter>
      </LoanState>
    </div>
  );
}

export default App;
