import React, { useState } from "react";
import { Container } from "react-bootstrap";
import padlit from "../assets/padlit.svg";
import selfmanagement from "../assets/selfmanagement.svg";
import padlitwhite from '../assets/padlitwhite.svg'
import selfmanagmentwhite from '../assets/selfmanagmentwhite.svg'
import { Link } from "react-router-dom";
const SelectPage = () => {
  const [selected, setSelected] = useState('')
  //console.log(selected)
  return (
    <div className="selectorpage text-center py-5">
      <Container>
        <h1 className="text-center selectpageh1 mt-sm-5 mb-5">
          Which Rental Model you want to Choose?
        </h1>
        <div className="images d-flex justify-content-around px-4">
          <div className="text-center d-flex flex-column justify-content-center">
            <div onClick={()=>setSelected('Padsplit')} className={`PageSelectorPadsplit ${selected === 'Padsplit' ? 'selected' : ''}`}>
              <img src={selected === 'Padsplit' ? padlitwhite : padlit} />
            </div>
            <h5 className="text-center mt-1">Padsplit</h5>
          </div>
          <div className="text-center d-flex flex-column justify-content-center">
            <div onClick={()=>setSelected('Management')} className={`PageSelectorPadsplit ${selected === 'Management' ? 'selected' : ''}`}>
              <img src={selected === 'Management' ? selfmanagmentwhite : selfmanagement} />
            </div>
            <h5 className="text-center mt-1">Self Management</h5>
          </div>
        </div>
        <Link to={`/${selected}`}>
        <button className="nxtbtn mt-4">Next</button>
        </Link>
      </Container>
    </div>
  );
};

export default SelectPage;
