import React, { useState, useRef, useEffect } from 'react';
import blue from '../assets/blue.png';
import black from '../assets/black.png';
import { Container } from 'react-bootstrap';
// import './ImageChanger.css';

const ImageChanger = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [left, setLeft] = useState(0);
  const [clipPathValue, setClipPathValue] = useState(50);
  const containerRef = useRef(null);
  const moveableDivRef = useRef(null);
  const [x, setX] = useState(0);
  const [right, setRight] = useState('100%');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    const handleMouseMove = (e) => {
      if (isDragging) {
        const container = containerRef.current;
        const moveableDiv = moveableDivRef.current;
        let x = e.clientX - startX;
        x = Math.max(0, Math.min(x, container.clientWidth - moveableDiv.clientWidth));
        setLeft(x);
      }
    };

    const handleMouseUp = () => {
      if (isDragging) {
        setIsDragging(false);
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, startX]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.clientX - moveableDivRef.current.offsetLeft);

  };

  const handleContainerMouseMove = (e) => {
    const container = containerRef.current;
    const containerWidth = container.clientWidth;
    const cursorPosition = e.clientX - container.getBoundingClientRect().left;
    const clipPath = ((containerWidth - cursorPosition) / containerWidth) * 100;
    setClipPathValue(clipPath);
    setRight(cursorPosition);
  };

  return (
    <>
      <Container>
        <div className='text_imagechanger text-center'>

          {/* <h5>WHY CHOOSE US</h5> */}
          <h1>Co-living vs. Single Family Rentals</h1>
          <p>
          Co-living transforms underutilized spaces into additional bedrooms, thereby enhancing revenue while reducing the cost per room for guests. The property illustration details how Co-living generates over 2.5 times more income ($3,747) compared to a traditional single tenant occupancy.          </p>
        </div>
      </Container>

      <div className="container flexer my-5">
        <div className='before_image_changer text-center'>
          <h1>BEFORE</h1>
          <hr />
          <h1>$2,350</h1>
          <h4>Monthly Rent</h4>
          <h5>4 Bed 3 Bath</h5>
        </div>
        <div className="twoimages">
          <img src={blue} className="dynamicImage" alt="blue" ref={containerRef} onMouseMove={handleContainerMouseMove} />
          <img
            src={black}
            className="dynamicImage posetionAb"
            alt="black"
            style={{ clipPath: `inset(0px ${clipPathValue}% 0px 0px)` }}
            ref={containerRef} onMouseMove={handleContainerMouseMove}
          />
          <div className='posabsloute' id="follow-div" style={{ left: right + 'px' }}>

          </div>
        </div>
        <div className='before_image_changer text-center'>
          <h1>AFTER</h1>
          <hr />
          <h1>$6,097</h1>
          <h4>Monthly Rent</h4>
          <h5>7 Bed 3 Bath</h5>
        </div>
      </div>
    </>

  );
};

export default ImageChanger;
