import React, { useState, useEffect } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import SwiperCore from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import videoimg from '../assets/babywoman.png'
import playbtn from '../assets/playbtn.png'
import { Container } from 'react-bootstrap';
// import 'swiper/swiper-bundle.min.css';
import ableright from '../assets/ableright.png'
import ableleft from '../assets/ableleft.png'
import disableleft from '../assets/disableleft.png'
import disableright from '../assets/disableright.png'
// import lockedPlaybtn from '../assets/lockedPlaybtn.png'; // Import locked play button image

SwiperCore.use([Navigation]);

const Swipertwo = () => {
  const [isLocked, setIsLocked] = useState(false); // State to track button lock status
  const [priv, setPriv] = useState(true)
  const [nxt, setNxt] = useState(false)

  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // State to track button disabled status
  const previousfun = () => {
    if (document.querySelector('.previus').classList.contains('swiper-button-disabled')) {
      //console.log("disabled")
      setPriv(true)
    } else {
      setPriv(false)

    }
    if (document.querySelector('.next').classList.contains('swiper-button-disabled')) {
      //console.log("disabled")
      setNxt(true)
    } else {
      setNxt(false)
    }
  }

  useEffect(() => {
    // Check if the button has a class named "disable"
    const button = document.querySelector('.posabs img');
    if (button && button.classList.contains('disable')) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, []); // Run once on component mount

  const toggleLock = () => {
    setIsLocked(!isLocked); // Toggle button lock status
  };
  return (
    <>
      <div style={{ marginTop: '100px' }}>
        <h4 className='text-center' style={{ color: '#1B71AB' }}>ASSET'S INFORMATION</h4>
        <h1 className='text-center' style={{ fontWeight: '600' }}>What's The Asset?</h1>
      </div>
      <div className='relswiper' style={{ marginTop: '50px' }}>
        <div className='absswiperslider d-flex justify-content-between'>
          <button className="customm-prev btnsnxtpre previus" onClick={previousfun} style={{ background: 'transparent', border: "none", height: '70px' }}><img style={{ height: '70px' }} src={priv ? disableleft : ableleft} /></button>
          <button className="customm-next btnsnxtpre next" onClick={previousfun} style={{ background: 'transparent', border: "none", height: '70px' }}><img style={{ height: '70px' }} src={nxt ? disableright : ableright} /></button>
        </div>

          <Container>
        <div className='swipertwoedit'>
            <Swiper
              navigation={{
                nextEl: '.customm-next',
                prevEl: '.customm-prev'
              }}
            >
              <SwiperSlide>
                <div className='posrel'>
                  <div className='d-flex flex-column' >
                  <img src={videoimg} style={{ width: '100%' }} alt="video" />
                  <h2 className='text-center mt-4' style={{ color: '#1B71AB' }}>Single Family Residential</h2>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='posrel'>
                <div className='d-flex flex-column' >
                  <img src={videoimg} style={{ width: '100%' }} alt="video" />
                  <h2 className='text-center mt-4' style={{ color: '#1B71AB' }}>Single Family Residential</h2>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='posrel'>
                <div className='d-flex flex-column' >
                  <img src={videoimg} style={{ width: '100%' }} alt="video" />
                  <h2 className='text-center mt-4' style={{ color: '#1B71AB' }}>Single Family Residential</h2>
                  </div>
                </div>
              </SwiperSlide>
              {/* Add more slides as needed */}
            </Swiper>

        </div>
          </Container>

      </div>
    </>
  )
}

export default Swipertwo
