import loadContext from './loadContext'
import { useState } from 'react'

 
const LoanState = (props) => {
    const [loans_value,setLoans_value] = useState()
    const [product,setProject] = useState(5.78)
  return (
    <loadContext.Provider value = {{loans_value,product,setLoans_value,setProject}}>
        {props.children}
        </loadContext.Provider>
  )
}

export default LoanState
