import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import SwiperCore from 'swiper/core'
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Button, Col, Row } from 'react-bootstrap';
import uperimg from '../assets/abc.png'
import girl from '../assets/girl.png'
import starts from "../assets/starts.png"
import ableleft from '../assets/ableleft.png'
import ableright from '../assets/ableright.png'
import disableleft from '../assets/disableleft.png'
import disableright from '../assets/disableright.png'
SwiperCore.use([Navigation]);

const Testimonials = () => {
    const [previous,setPrevious]=useState(true)
    const [nextt,setNextt]= useState(false)
    const clickedupdown=()=>{

    if (document.querySelector('.down').classList.contains('swiper-button-disabled')) {
        //console.log("disabled")
        setPrevious(true)
      } else {
        setPrevious(false)
      }
      if (document.querySelector('.upp').classList.contains('swiper-button-disabled')) {
        //console.log("disabled")
        setNextt(true)
      } else {
        setNextt(false)}
    }

    return (
        <div className='vertical py-5' >
            <Row className='heightincre' style={{  width: '100%' }}>
                <Col lg='4' className='px-5 py-5 d-flex flex-column justify-content-around'>
                    <div className='testm px-3 d-flex flex-column justify-content-center'>
                        <h4 className=''>TESTIMONIALS</h4 >
                        <h1 className=''>
                            Look What Our Customers Say!
                        </h1>
                    </div>
                    <div className='d-flex justify-content-start'>
                        <Button className='down' onClick={clickedupdown} style={{background:'transparent',border:'none'}} ><img src={previous? disableleft :ableleft } /> </Button>
                        <Button className='upp' style={{background:'transparent',border:'none'}} onClick={clickedupdown}><img src={nextt? disableright :ableright} /> </Button>
                    </div>
                </Col>
                <Col lg='8' className='h-100 p-5' style={{ background: '#F8FBFC' }}>
                    <div className='h-100 bg-white w-100 h-100 testimonialsinner'>

                        <Swiper
                            direction={'vertical'}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={{
                                nextEl: '.upp',
                                prevEl: '.down'
                            }}
                            modules={[Pagination]}
                            className="mySwiper h-100"
                        >
                            <SwiperSlide>
                                <div className='py-xl-5 py-sm-2 px-5'>

                                    <img src={uperimg} style={{ height: '30px' }} />
                                    <div className='mt-3 py-3'>
                                        <h5 style={{ fontWeight: '400' }} className='highly'>
                                            I highly recommend Jodi J. Appleby. She was attentive to our needs and worked tirelessly to find us the perfect home. We couldn't be happier with our new place!
                                        </h5>
                                    </div>
                                    <hr />
                                    <div className='rowcolumn d-flex justify-content-between'>
                                        <div className='d-flex'>
                                            <img src={girl} style={{ height: '60px' }} />
                                            <h5 className='mx-4' style={{ alignSelf: 'center', fontWeight: '500' }}>Barbara D. Smith</h5>
                                        </div>
                                        <img src={starts} style={{ height: '20px', alignSelf: 'center' }} />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='py-xl-5 py-sm-2 px-5'>
                                    <img src={uperimg} style={{ height: '30px' }} />
                                    <div className='mt-3 py-3'>
                                        <h5 style={{ fontWeight: '400' }} className='highly'>
                                            I highly recommend Jodi J. Appleby. She was attentive to our needs and worked tirelessly to find us the perfect home. We couldn't be happier with our new place!
                                        </h5>
                                    </div>
                                    <hr />
                                    <div className='d-flex rowcolumn justify-content-between'>
                                        <div className='d-flex'>
                                            <img src={girl} style={{ height: '60px' }} />
                                            <h5 className='mx-4' style={{ alignSelf: 'center', fontWeight: '500' }}>Barbara D. Smith</h5>
                                        </div>
                                        <img src={starts} style={{ height: '20px', alignSelf: 'center' }} />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className='py-xl-5 py-sm-2 px-5'>
                                    <img src={uperimg} style={{ height: '30px' }} />
                                    <div className='mt-3 py-3'>
                                        <h5 style={{ fontWeight: '400' }} className='highly'>
                                            I highly recommend Jodi J. Appleby. She was attentive to our needs and worked tirelessly to find us the perfect home. We couldn't be happier with our new place!
                                        </h5>
                                    </div>
                                    <hr />
                                    <div className='d-flex rowcolumn justify-content-between'>
                                        <div className='d-flex'>
                                            <img src={girl} style={{ height: '60px' }} />
                                            <h5 className='mx-4' style={{ alignSelf: 'center', fontWeight: '500' }}>Barbara D. Smith</h5>
                                        </div>
                                        <img src={starts} style={{ height: '20px', alignSelf: 'center' }} />
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>

                </Col>
            </Row>
        </div>

    );
}

export default Testimonials;
