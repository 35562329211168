import React from 'react'
import Home_hero from '../components/Home_hero'
import Navbar from '../components/Navbar'
import Navigating from '../components/Navigating'
import Benefits from '../components/Benefits'
import Contactushome from '../components/Contactushome'
import Tenants from '../components/Tenants'
import Tenant from '../components/Tenant'
import Tistmonials from '../components/Tistmonials'
import Faqs from '../components/Faqs'
import Footer from '../components/Footer'
import SwiperComponent from '../components/home/Swiper'
import Swiperrr from '../components/Swiper2'
import videoimg from '../assets/babywoman.png'
import ImageChanger from '../components/ImageChanger'
import Calculator from '../components/home/Calculator'


const Home = () => {
    const data = [
        {id: 1, img: videoimg},
        {id: 2, img: videoimg},
        {id: 3, img: videoimg},
        {id: 4, img: videoimg},
    ]
    return (
        <div>
            <Navbar bigbtn="Go to Calculator" bigbtnlink=""/>
            <Home_hero />
            <ImageChanger/>
           <Calculator/>
            {/* <Benefits/> */}
            {/* <Swipertwo/> */}
            {/* <SwiperComponent mainTitle={"ASSET'S INFORMATION"} secondaryTitle={"What's The Asset?"} data={data} /> */}
            {/* <Swiperrr/> */}
            {/* <h2 className='text-center mt-5' style={{color:'#1B71AB'}}>Single Family Residential</h2> */}
            <div className='mt-5'>
                <Contactushome/>
            </div>
            <Navigating />
            {/* <Tenants/> */}
            {/* <Tenant/> */}
            {/* <Faqs/> */}
            <div>
            {/* <Tistmonials/> */}
            </div>
           
            <Footer/>
        </div>
    )
}

export default Home
