import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import hands from '../assets/hands.png'
import face from '../assets/face.png'
import house from '../assets/house.png'
import MyVerticallyCenteredModal from './modal';
import under_con from '../assets/under_con.svg'
import { Col, Container, Row } from 'react-bootstrap';
const Navigating = () => {
    const [modalShow, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState({});

    const handleShowModal = (content) => {
        setModalContent(content);
        setModalShow(true);
    };
    return (
        <div className='navagating'>
            <h4 className='text-center' style={{ color: '#1B71AB' }}>NAVIGATING INFORMATION</h4>
            <h1 className='text-center' style={{ fontWeight: '600' }}>Who Benefits More?</h1>
            <Container>
                <div className='posrelative'>
                    <div className='left_line'></div>
                    <Row className='mt-5 justify-content-center'>
                        <Col md='12' lg='12' className='d-flex flex-column justify-content-center'>
                            <Card style={{ border: 'none', maxWidth: '380px', alignSelf: 'center', backgroundColor: 'transparent' }}>
                                <Card.Img style={{ alignSelf: 'center', background: 'white', borderRadius: '50%' }} variant="top" src={face} />
                                <Card.Body className='card_body_jsx'>
                                    <Card.Title> <h3 className='text-center realnavigating'>Real Estate Owner</h3></Card.Title>
                                    <Card.Text className='text-center'>
                                        <a style={{ color: 'blue', textDecoration: 'underline', 'color': 'black', 'textDecoration': 'none', 'cursor': 'pointer' }} onClick={() => handleShowModal({ image: under_con })}>
                                            Click Me
                                        </a>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md='6' lg='6' className='d-flex flex-column justify-content-center'>
                            <Card className='two_card' style={{ border: 'none', maxWidth: '380px', alignSelf: 'center', }}>
                                <Card.Img style={{ alignSelf: 'center', borderRadius: '50%' }} variant="top" src={house} />
                                <Card.Body className='card_body_jsx'>
                                    <Card.Title>
                                        <h3 className='text-center realnavigating'>Real Estate Agent</h3>
                                    </Card.Title>
                                    <Card.Text className='text-center '>
                                        <a style={{ color: 'blue', textDecoration: 'underline', 'color': 'black', 'textDecoration': 'none', 'cursor': 'pointer' }} onClick={() => handleShowModal({ image: under_con })}>
                                            Click Me
                                        </a>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md='6' lg='6' className='d-flex flex-column justify-content-center'>
                            <Card className='three_card' style={{ border: 'none', maxWidth: '380px', alignSelf: 'center' }}>
                                <Card.Img style={{ alignSelf: 'center', borderRadius: '50%' }} variant="top" src={hands} />
                                <Card.Body className='card_body_jsx'>
                                    <Card.Title><h3 className='text-center realnavigating'>Wholesaler</h3></Card.Title>
                                    <Card.Text className='text-center'>
                                        <a style={{ color: 'blue', textDecoration: 'underline', 'color': 'black', 'textDecoration': 'none', 'cursor': 'pointer' }} onClick={() => handleShowModal({ image: under_con })}>
                                            Click Me
                                        </a>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <div className='right_line'></div>
                </div>
            </Container>
            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                image={modalContent.image}
                data={modalContent.data}
                Button={modalContent.Button}
            />

        </div>
    )
}

export default Navigating
