import React from 'react'
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
const Calculator = () => {
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <Table className="w-100 closingtable m-0" style={{borderRadius:'20px',overflow:'hidden'}} responsive>
                            <tr>
                                <td colSpan='3' style={{ background: '#1B71AB' }}>
                                    <h4 style={{ background: 'transparent', fontWeight: '400' }} className='text-center text-white m-0 py-3'>4 BEDROOMS - SINGLE TENTANT</h4>
                                </td>
                            </tr>
                            <tr
                                className="bottomborder"
                                style={{ background: "", borderBottom: '1px solid #60a6d42b' }}
                            >
                                <td colSpan='3' style={{ background: '#1B71AB1A' }}>
                                    <h6 className='mb-0 py-2 px-4' style={{background:'transparent'}}>Capital</h6>
                                </td>
                            </tr>
                            <tr
                                className="bottomborder"
                                style={{ background: "#1B71AB1A", borderBottom: '1px solid #60a6d42b' }}
                            >
                                <td className="py-2 px-4">
                                    <h5
                                        style={{
                                            background: "transparent",
                                            alignSelf: "center",
                                            fontWeight: '400'
                                        }}
                                        className="m-0"
                                    >
                                        Purchase Price
                                    </h5>
                                </td>

                                <td className="px-4"><h5
                                    style={{ background: "transparent", fontWeight: '400' }}
                                    className="text-center m-0"

                                >
                                
                                </h5></td>
                                <td className="px-4">
                                    <h5
                                        style={{ background: "transparent", fontWeight: '400' }}
                                        className="text-end m-0"
                                    >
                                        $363438
                                    </h5>
                                </td>
                            </tr>
                            <tr
                                className="bottomborder"
                                style={{ background: "#1B71AB1A", borderBottom: '1px solid #60a6d42b' }}
                            >
                                <td className="py-2 px-4">
                                    <h5
                                        style={{
                                            background: "transparent",
                                            alignSelf: "center",
                                            fontWeight: '400'
                                        }}
                                        className="m-0"
                                    >
                                       Closing Costs
                                    </h5>
                                </td>

                                <td className="px-4"><h5
                                    style={{ background: "transparent", fontWeight: '400' }}
                                    className="text-center m-0"
                                >
                                    1.36%
                                </h5></td>
                                <td className="px-4">
                                    <h5
                                        style={{ background: "transparent", fontWeight: '400' }}
                                        className="text-end m-0"
                                    >
                                        $4,943
                                    </h5>
                                </td>
                            </tr>
                            <tr
                                className="bottomborder"
                                style={{ background: "#1B71AB1A", borderBottom: '1px solid #60a6d42b' }}
                            >
                                <td className="py-2 px-4">
                                    <h5
                                        style={{
                                            background: "transparent",
                                            alignSelf: "center",
                                            fontWeight: '400'
                                        }}
                                        className="m-0"
                                    >
                                        Total Cash Invested 

                                    </h5>
                                </td>
                                <td className="px-4"></td>
                                <td className="px-4">
                                    <h5
                                        style={{ background: "transparent", fontWeight: '400' }}
                                        className="text-end m-0"
                                    >
                                       $368,381
                                    </h5>
                                </td>
                            </tr>
                            <tr
                                className="bottomborder"
                                style={{ background: "#1B71AB1A" }}
                            >
                                <td className="py-2 px-4">
                                    <h5
                                        style={{
                                            background: "transparent",
                                            alignSelf: "center",
                                            fontWeight: '400'
                                        }}
                                        className="m-0"
                                    >
                                        Income
                                    </h5>
                                </td>
                                <td className="px-4"></td>
                                <td className="px-4">
                                    <h5
                                        style={{ background: "transparent", fontWeight: '400' }}
                                        className="text-end m-0"
                                    >
                                       ANNUAL
                                    </h5>
                                </td>
                            </tr>
                            <tr
                                className="bottomborder"
                                style={{ background: "#1B71AB1A" }}
                            >
                                <td className="py-2 px-4">
                                    <h5
                                        style={{
                                            background: "transparent",
                                            alignSelf: "center",
                                            fontWeight: '400'
                                        }}
                                        className="m-0"
                                    >
                                        Gross Rent 
                                    </h5>
                                </td>
                                <td className="px-4"></td>
                                <td className="px-4">
                                    <h5
                                        style={{ background: "transparent", fontWeight: '400' }}
                                        className="text-end m-0"
                                    >
                                       $25,851
                                    </h5>
                                </td>
                            </tr> 
                             <tr
                                className="bottomborder"
                                style={{ background: "#1B71AB1A" }}
                            >
                                <td className="py-2 px-4">
                                    <h5
                                        style={{
                                            background: "transparent",
                                            alignSelf: "center",
                                            fontWeight: '400'
                                        }}
                                        className="m-0"
                                    >
                                       Operating Expenses

                                    </h5>
                                </td>
                                <td className="px-4"></td>
                                <td className="px-4">
                                    <h5
                                        style={{ background: "transparent", fontWeight: '400' }}
                                        className="text-end m-0"
                                    >
                                       ANNUAL
                                    </h5>
                                </td>
                            </tr> 
                             <tr
                                className="bottomborder"
                                style={{ background: "#1B71AB1A" }}
                            >
                                <td className="py-2 px-4">
                                    <h5
                                        style={{
                                            background: "transparent",
                                            alignSelf: "center",
                                            fontWeight: '400'
                                        }}
                                        className="m-0"
                                    >
                                        Total Annual Expense

                                    </h5>
                                </td>
                                <td className="px-4"></td>
                                <td className="px-4">
                                    <h5
                                        style={{ background: "transparent", fontWeight: '400' }}
                                        className="text-end m-0"
                                    >
                                       $7,415
                                    </h5>
                                </td>
                            </tr> 
                             <tr
                                className="bottomborder"
                                style={{ background: "#1B71AB1A" }}
                            >
                                <td className="py-2 px-4">
                                    <h5
                                        style={{
                                            background: "transparent",
                                            alignSelf: "center",
                                            fontWeight: '400'
                                        }}
                                        className="m-0"
                                    >
                                        Net Rent After Expenses
                                    </h5>
                                </td>
                                <td className="px-4"></td>
                                <td className="px-4">
                                    <h5
                                        style={{ background: "transparent", fontWeight: '400' }}
                                        className="text-end m-0"
                                    >
                                       $18,436
                                    </h5>
                                </td>
                            </tr>
                            <tr
                                className="bottomborder"
                                style={{ background: "rgb(0 0 0 / 13%)" }}
                            >
                                <td className="py-2 px-4">
                                    <h5
                                        style={{
                                            background: "transparent",
                                            alignSelf: "center",
                                            fontWeight: '400'
                                        }}
                                        className="m-0"
                                    >
                                        Yield / Unleveraged Cash On Cash
                                    </h5>
                                </td>
                                <td className="px-4"></td>
                                <td className="px-4">
                                    <h5
                                        style={{ background: "transparent", fontWeight: '400' }}
                                        className="text-end m-0"
                                    >
                                       5.00%
                                    </h5>
                                </td>
                            </tr>
                        </Table>
                    </div>
                    <div className="col-lg-6">
                    <Table className="w-100 closingtable m-0" style={{borderRadius:'20px',overflow:'hidden'}} responsive>
                            <tr>
                                <td colSpan='3' style={{ background: '#1B71AB' }}>
                                    <h4 style={{ background: 'transparent', fontWeight: '400' }} className='text-center text-white m-0 py-3'>7 BEDROOMS - CO-LIVING </h4>
                                </td>
                            </tr>
                            <tr
                                className="bottomborder"
                                style={{ background: "", borderBottom: '1px solid #60a6d42b' }}
                            >
                                <td colSpan='3' style={{ background: '#1B71AB1A' }}>
                                    <h6 className='mb-0 py-2 px-4' style={{background:'transparent'}}>Capital</h6>
                                </td>
                            </tr>
                            <tr
                                className="bottomborder"
                                style={{ background: "#1B71AB1A", borderBottom: '1px solid #60a6d42b' }}
                            >
                                <td className="py-2 px-4">
                                    <h5
                                        style={{
                                            background: "transparent",
                                            alignSelf: "center",
                                            fontWeight: '400'
                                        }}
                                        className="m-0"
                                    >
                                        Purchase Price
                                    </h5>
                                </td>

                                <td className="px-4"><h5
                                    style={{ background: "transparent", fontWeight: '400' }}
                                    className="text-center m-0"

                                >
                                
                                </h5></td>
                                <td className="px-4">
                                    <h5
                                        style={{ background: "transparent", fontWeight: '400' }}
                                        className="text-end m-0"
                                    >
                                        $363438
                                    </h5>
                                </td>
                            </tr>
                            <tr
                                className="bottomborder"
                                style={{ background: "#1B71AB1A", borderBottom: '1px solid #60a6d42b' }}
                            >
                                <td className="py-2 px-4">
                                    <h5
                                        style={{
                                            background: "transparent",
                                            alignSelf: "center",
                                            fontWeight: '400'
                                        }}
                                        className="m-0"
                                    >
                                       Closing Costs
                                    </h5>
                                </td>

                                <td className="px-4"><h5
                                    style={{ background: "transparent", fontWeight: '400' }}
                                    className="text-center m-0"
                                >
                                    1.36%
                                </h5></td>
                                <td className="px-4">
                                    <h5
                                        style={{ background: "transparent", fontWeight: '400' }}
                                        className="text-end m-0"
                                    >
                                        $4,943
                                    </h5>
                                </td>
                            </tr>
                            <tr
                                className="bottomborder"
                                style={{ background: "#1B71AB1A", borderBottom: '1px solid #60a6d42b' }}
                            >
                                <td className="py-2 px-4">
                                    <h5
                                        style={{
                                            background: "transparent",
                                            alignSelf: "center",
                                            fontWeight: '400'
                                        }}
                                        className="m-0"
                                    >
                                        Total Cash Invested 

                                    </h5>
                                </td>
                                <td className="px-4"></td>
                                <td className="px-4">
                                    <h5
                                        style={{ background: "transparent", fontWeight: '400' }}
                                        className="text-end m-0"
                                    >
                                       $368,381
                                    </h5>
                                </td>
                            </tr>
                            <tr
                                className="bottomborder"
                                style={{ background: "#1B71AB1A", borderBottom: '1px solid #60a6d42b' }}
                            >
                                <td className="py-2 px-4">
                                    <h5
                                        style={{
                                            background: "transparent",
                                            alignSelf: "center",
                                            fontWeight: '400'
                                        }}
                                        className="m-0"
                                    >
                                        Income
                                    </h5>
                                </td>
                                <td className="px-4"></td>
                                <td className="px-4">
                                    <h5
                                        style={{ background: "transparent", fontWeight: '400' }}
                                        className="text-end m-0"
                                    >
                                       ANNUAL
                                    </h5>
                                </td>
                            </tr>
                            <tr
                                className="bottomborder"
                                style={{ background: "#1B71AB1A", borderBottom: '1px solid #60a6d42b' }}
                            >
                                <td className="py-2 px-4">
                                    <h5
                                        style={{
                                            background: "transparent",
                                            alignSelf: "center",
                                            fontWeight: '400'
                                        }}
                                        className="m-0"
                                    >
                                        Gross Rent 
                                    </h5>
                                </td>
                                <td className="px-4"></td>
                                <td className="px-4">
                                    <h5
                                        style={{ background: "transparent", fontWeight: '400' }}
                                        className="text-end m-0"
                                    >
                                       $69,506
                                    </h5>
                                </td>
                            </tr> 
                             <tr
                                className="bottomborder"
                                style={{ background: "#1B71AB1A", borderBottom: '1px solid #60a6d42b' }}
                            >
                                <td className="py-2 px-4">
                                    <h5
                                        style={{
                                            background: "transparent",
                                            alignSelf: "center",
                                            fontWeight: '400'
                                        }}
                                        className="m-0"
                                    >
                                       Operating Expenses

                                    </h5>
                                </td>
                                <td className="px-4"></td>
                                <td className="px-4">
                                    <h5
                                        style={{ background: "transparent", fontWeight: '400' }}
                                        className="text-end m-0"
                                    >
                                       ANNUAL
                                    </h5>
                                </td>
                            </tr> 
                             <tr
                                className="bottomborder"
                                style={{ background: "#1B71AB1A", borderBottom: '1px solid #60a6d42b' }}
                            >
                                <td className="py-2 px-4">
                                    <h5
                                        style={{
                                            background: "transparent",
                                            alignSelf: "center",
                                            fontWeight: '400'
                                        }}
                                        className="m-0"
                                    >
                                        Total Annual Expense

                                    </h5>
                                </td>
                                <td className="px-4"></td>
                                <td className="px-4">
                                    <h5
                                        style={{ background: "transparent", fontWeight: '400' }}
                                        className="text-end m-0"
                                    >
                                       $19,474
                                    </h5>
                                </td>
                            </tr> 
                             <tr
                                className="bottomborder"
                                style={{ background: "#1B71AB1A", borderBottom: '1px solid #60a6d42b' }}
                            >
                                <td className="py-2 px-4">
                                    <h5
                                        style={{
                                            background: "transparent",
                                            alignSelf: "center",
                                            fontWeight: '400'
                                        }}
                                        className="m-0"
                                    >
                                        Net Rent After Expenses
                                    </h5>
                                </td>
                                <td className="px-4"></td>
                                <td className="px-4">
                                    <h5
                                        style={{ background: "transparent", fontWeight: '400' }}
                                        className="text-end m-0"
                                    >
                                       $50,032
                                    </h5>
                                </td>
                            </tr>
                            <tr
                                className="bottomborder"
                                style={{ background: "rgb(0 0 0 / 13%)", borderBottom: '1px solid #60a6d42b' }}
                            >
                                <td className="py-2 px-4">
                                    <h5
                                        style={{
                                            background: "transparent",
                                            alignSelf: "center",
                                            fontWeight: '400'
                                        }}
                                        className="m-0"
                                    >
                                        Yield / Unleveraged Cash On Cash
                                    </h5>
                                </td>
                                <td className="px-4"></td>
                                <td className="px-4">
                                    <h5
                                        style={{ background: "transparent", fontWeight: '400' }}
                                        className="text-end m-0"
                                    >
                                       12.97    %
                                    </h5>
                                </td>
                            </tr>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Calculator
