import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        
      >
         
        <Modal.Body className="d-flex flex-column text-center justify-content-center py-5" style={{'height':'70vh','background':'#206EB21A'}}>
           {props.data?  <h1 className='mb-5 data_h1'>{props.data}</h1>: '' }
          {props.image?<img src={props.image} className='text-center image-fluid img-fluid py-5' />: ''}
          <div>
           {props.Button?  <Link  to='/pageSelecter'><Button size="lg" className='py-3 px-5 mt-4 regester_modal_btn'  >{props.Button}</Button></Link>: '' }
          </div>
        </Modal.Body>
         
      </Modal>
    );
  }

  export default MyVerticallyCenteredModal