import React, { useState, useEffect, useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import InformationPopover from "./Information";
import Button from "react-bootstrap/Button";
import $ from 'jquery';
import LoanComponent from "./LoanComponent";
import Blended from "./Blended";
import loadContext from "../context/loadContext";
const Padsplit = () => {
  const a = useContext(loadContext)

  const [loanValue, setLoanValue] = useState('')
  const [interest, setInterest] = useState('')
  const [term, setTerm] = useState('')





  let [price, setPrice] = useState(170069);
  let [existing, setExisting] = useState(3);
  let [closing_cost, setClosing_cost] = useState(1.36);
  let [closing_value, setClosing_value] = useState(2312.00);
  let [firstTotal, setFirstTotal] = useState(172312);
  let [conversionCostPerRoom, setConversionCostPerRoo] = useState(7500);
  let [finalconversionCostPerRoom, setFinalconversionCostPerRoo] = useState(22500);
  let [conversionCostPerBathroom, setConversionCostPerBathroom] = useState(10000);
  let [conversionFurnishingPerRoom, setConversionFurnishingPerRoo] = useState(1000);
  let [finalconversionFurnishingPerRoom, setFinalconversionFurnishingPerRoom] = useState(6000)
  let [rent, setRent] = useState(600.0);
  let [rent_month, setRent_month] = useState(3600);
  let [vacancy_month_per, setVacancy_month_per] = useState(5);
  let [transaction_month_per, setTransaction_month_per] = useState(2.67);
  let [vacancy_month, setVacancy_month] = useState(180);
  let [transaction_month, setTransaction_month] = useState(96.12);
  let [grossRent, setGrossRent] = useState(3323.88);
  let [rent_year, setRent_year] = useState();
  let [vacancy_year, setVacancy_year] = useState(43200);
  let [transaction_year, setTransaction_year] = useState(1153.44);
  let [grossRent_year, setGrossRent_year] = useState(39886.56);
  let [projected, setProjected] = useState(6);
  const [key, setKey] = useState("Padsplit");
  const [loancounter, setLoancounter] = useState(1)
  let [padsplit_fee_per, setPadsplit_fee_per] = useState(12);
  let [padsplit_fee, setpadsplit_fee] = useState(432);
  let [padsplit_fee_year, setPadsplit_fee_year] = useState(5184);

  let [utilities_per, setUtilities_per] = useState(12.38);
  let [utilities, setUtilities] = useState(411.50);
  let [utilities_year, setUtilities_year] = useState(4937.96);

  let [maintenance_per, setMaintenance_per] = useState(5.85);
  let [maintenance, setMaintenance] = useState(194.45);
  let [maintenance_year, setMaintenance_year] = useState(2333.36);

  let [subcontract_per, setSubcontract_per] = useState(4.39);
  let [subcontract, setSubcontract] = useState(145.92);
  let [subcontract_year, setSubcontract_year] = useState(1751.02);

  let [property_managment_per, setProperty_managment_per] = useState(10);
  let [property_managment, setProperty_managment] = useState(332.39);
  let [property_managment_year, setProperty_managment_year] = useState(3988.66)

  let [insurance_per, setInsurance_per] = useState(2.20);
  let [insurance, setInsurance] = useState(73.13);
  let [insurance_year, setInsurance_year] = useState(877.50)

  let [property_tax_per, setProperty_tax_per] = useState(0);
  let [property_tax, setProperty_tax] = useState(0);
  let [property_tax_year, setProperty_tax_year] = useState(0);


  let [total_annaual_expense, setTotal_annaual_expense] = useState(1589.39);
  let [total_annaual_expense_year, setTotal_annaual_expense_year] = useState(19072.50);
  let [Leveraged, setLeveraged] = useState(18);
  let [total_annaual_expense_per, setTotal_annaual_expense_per] = useState(46.82);
  let [net_rent_after_exp, setNet_rent_after_exp] = useState(1734.49);
  let [net_rent_after_exp_year, setNet_rent_after_exp_year] = useState(20814.06);
  let [loan_to_value, setLoan_to_value] = useState(50)
  let [blended, setBlended] = useState(5.67)
  let [mainterm, setMainterm] = useState(360)
  let [mainLoanAmount, setMainmainLoanAmount] = useState()
  let [mainEquity, setMainEquity] = useState()
  let [debttt, setDebettt] = useState()
  let [yearly_debt, seYearly_debt] = useState(5888.76)
  let [cashFlow, setCashFlow] = useState(1242.15)
  let [CashFlow_year, setCashFlow_year] = useState(14905.98)
  let [Wholesaler, setWholesaler] = useState(169999.875)
  let [WholesalerFee, setWholesalerFee] = useState(15000)
  let [priceWholesaler, setPriceWholesaler] = useState(307288.84)
  let [mao, setMoa] = useState()
  const [totalProduct, setTotalProduct] = useState(0);
  const [yeald, setYeald] = useState(9.870005937919577)
  // net_rent_after_exp_year
  const [loans_value, setLoans_value] = useState({
    loanValue: 50,
    interest: 50,
    term: 50,
    loan_amount_total: 85034.97,
  });




  useEffect(() => {
    setMainmainLoanAmount(loan_to_value / 100 * price)
  }, [loan_to_value, price])
  // 2068
  useEffect(() => {
    console.log(finalconversionCostPerRoom, finalconversionFurnishingPerRoom, conversionCostPerBathroom, closing_value, '-------------------------------')
    setMainEquity(Number(closing_value) + finalconversionCostPerRoom + finalconversionFurnishingPerRoom + conversionCostPerBathroom)

  }, [finalconversionCostPerRoom, conversionCostPerBathroom, closing_value, finalconversionFurnishingPerRoom])

  useEffect(() => {
    setMoa(price - WholesalerFee)
  }, [priceWholesaler])
  const handleCalculate = () => {
    // const I24 = blended; // Example annual interest rate (5%)
    // const I25 = mainterm; // Example number of payments (60 months)
    // const I26 = mainLoanAmount; // Example loan amount ($10,000)

    // const monthlyInterestRate = I24 / 100 / 12;
    // const numberOfPayments = I25;
    // const loanAmount = I26;

    // const payment = calculatePMT(monthlyInterestRate, numberOfPayments, loanAmount);
    // console.log(`Monthly Payment: $${payment.toFixed(2) + 1}`);
    // setDebettt((payment + 1 ).toFixed(2))
    // seYearly_debt(12*debttt)

  }
  useEffect(() => {
    const newClosingValue = (price * closing_cost / 100).toFixed(2);
    setClosing_value(newClosingValue);
    setFirstTotal(Number(price) + Number(newClosingValue))
  }, [price, closing_cost]);
  function yeald_change() {
    setPrice(
      ((grossRent_year - total_annaual_expense_year) / yeald / 100) -
      (closing_value + finalconversionCostPerRoom + finalconversionFurnishingPerRoom + conversionCostPerBathroom)
    );

    // useEffect(() => {
    // }, [
    //   grossRent_year, 
    //   total_annaual_expense_year, 
    //   yeald, 
    //   closing_value, 
    //   finalconversionCostPerRoom, 
    //   finalconversionFurnishingPerRoom, 
    //   conversionCostPerBathroom
    // ]);
  }
  const handleLoanChange = (index, field, value) => {
    const updatedLoans = loans_value.map((loan, i) => {
      if (i === index) {
        let updatedLoan = { ...loan, [field]: value };

        if (field === 'loanValue') {

          updatedLoan = { ...updatedLoan, ['loan_amount_total']: (value / 100) * price };
        }
        return updatedLoan;
      }
      return loan;
    });
    setLoans_value(updatedLoans);
    console.log(loans_value, '_________________________________________________________________________________________________++++++++++++++++++++++++++++++++++++++++++++++++++++++++')
  };
  // useEffect(()=>{
  //   handleLoanChange()
  // },[price])
  useEffect(() => {
    const data = ((net_rent_after_exp_year / (Number(firstTotal) + Number(finalconversionCostPerRoom) + Number(conversionCostPerBathroom) + Number(finalconversionFurnishingPerRoom))) * 100)
    setYeald((((net_rent_after_exp_year / (Number(firstTotal) + Number(finalconversionCostPerRoom) + Number(conversionCostPerBathroom) + Number(finalconversionFurnishingPerRoom))) * 100)))
    // yeald_change()

  })
  useEffect(() => {
    const dataaaa = yeald / 100
    const price_abc = (((grossRent_year - total_annaual_expense_year) / dataaaa)) - (Number(closing_value) + finalconversionCostPerRoom + finalconversionFurnishingPerRoom + conversionCostPerBathroom)
    if (!isNaN(price_abc)) {

      setPrice(price_abc);
    }
    console.log('price is ', Math.round(price_abc))
  }, [yeald])


  function calculatePMT(rate, nper, pv, fv = 0, type = 0) {
    if (rate !== 0) {
      const q = Math.pow(1 + rate, nper);
      return -(rate * (fv + q * pv)) / ((-1 + q) * (1 + rate * type));
    } else {
      return -(pv + fv) / nper;
    }
  }


  useEffect(() => {
    if (Array.isArray(loans_value)) {
      let totalValue = 0;

      for (let index = 0; index < loans_value.length; index++) {
        totalValue += Number(loans_value[index].loanValue);
      }
      const sumOfProducts = loans_value.reduce((sum, loan) => {
        return sum + (loan.loanValue / 100) * loan.interest;
      }, 0);
      setBlended(sumOfProducts)
      setTotalProduct(sumOfProducts);
      a.setProject(sumOfProducts)
      console.log('Sum of products:', sumOfProducts);
      setLoan_to_value(totalValue);
      console.log('Total loan value:', totalValue);
    }
  }, [loans_value]);



  useEffect(() => {
    a.setLoans_value(loans_value)

  }, [loans_value])


  useEffect(() => {
    setPadsplit_fee_year(rent_year * (padsplit_fee_per / 100))
    console.log('________________________________________________________________')
  }, [rent_year, padsplit_fee_per])

  useEffect(() => {
    let newvalue = (grossRent_year * (utilities_per / 100)).toFixed(2);
    setUtilities_year(newvalue)
  }, [grossRent_year, utilities_per])

  useEffect(() => {
    let newvalue = (grossRent_year * (maintenance_per / 100)).toFixed(2);
    setMaintenance_year(newvalue)
  }, [grossRent_year, maintenance_per])

  useEffect(() => {
    let newvalue = (grossRent_year * (property_managment_per / 100)).toFixed(2);
    setProperty_managment_year(newvalue)
  }, [grossRent_year, property_managment_per])

  useEffect(() => {
    let newvalue = (grossRent_year * (subcontract_per / 100)).toFixed(2);
    setSubcontract_year(newvalue)
  }, [grossRent_year, subcontract_per])

  useEffect(() => {
    let newvalue = (grossRent_year * (insurance_per / 100)).toFixed(2);
    setInsurance_year(newvalue)
  }, [grossRent_year, insurance_per])

  useEffect(() => {
    setNet_rent_after_exp(grossRent - total_annaual_expense)
  }, [grossRent, total_annaual_expense])

  useEffect(() => {
    let value = (grossRent_year - total_annaual_expense_year).toFixed(2)
    setNet_rent_after_exp_year(value)
  }, [grossRent_year, total_annaual_expense_year])


  useEffect(() => {
    const newClosingValue = (padsplit_fee_per + utilities_per + maintenance_per + subcontract_per + property_managment_per + insurance_per + property_tax_per).toFixed(2);
    setTotal_annaual_expense_per(newClosingValue)
  }, [padsplit_fee_per, utilities_per, maintenance_per, subcontract_per, property_managment_per, insurance_per, property_tax_per])

  useEffect(() => {
    const newClosingValue = (padsplit_fee + Number(utilities) + Number(maintenance) + Number(subcontract) + Number(property_managment) + Number(insurance) + Number(property_tax)).toFixed(2);;
    setTotal_annaual_expense(newClosingValue)
  }, [padsplit_fee, utilities, maintenance, subcontract, property_managment, insurance, property_tax])

  useEffect(() => {
    const newClosingValue = (padsplit_fee_year + Number(utilities_year) + Number(maintenance_year) + Number(subcontract_year) + Number(property_managment_year) + Number(insurance_year) + Number(property_tax_year)).toFixed(2);;
    setTotal_annaual_expense_year(newClosingValue)
  }, [padsplit_fee_year, utilities_year, maintenance_year, subcontract_year, property_managment_year, insurance_year, property_tax_year])



  useEffect(() => {
    const newClosingValue = (grossRent * (insurance_per / 100)).toFixed(2);
    setInsurance(newClosingValue)
  }, [grossRent, insurance_per])


  useEffect(() => {
    const newClosingValue = (grossRent * (property_managment_per / 100)).toFixed(2);
    setProperty_managment(newClosingValue)
  }, [grossRent, property_managment_per])
  useEffect(() => {
    const newClosingValue = (grossRent * (subcontract_per / 100)).toFixed(2);

    setSubcontract(newClosingValue)
  }, [grossRent, subcontract_per])
  useEffect(() => {
    const newClosingValue = (grossRent * (maintenance_per / 100)).toFixed(2);
    setMaintenance(newClosingValue)
  }, [grossRent, maintenance_per])
  useEffect(() => {
    const newClosingValue = (grossRent * (utilities_per / 100)).toFixed(2);

    setUtilities(newClosingValue)
  }, [grossRent, utilities_per])
  useEffect(() => {
    setpadsplit_fee(rent_month * (padsplit_fee_per / 100))
  }, [rent_month, padsplit_fee_per])


  useEffect(() => {
    setFinalconversionCostPerRoo((projected - existing) * conversionCostPerRoom)
  }, [conversionCostPerRoom, existing, projected])

  useEffect(() => {
    setFinalconversionFurnishingPerRoom(conversionFurnishingPerRoom * projected)
  }, [conversionFurnishingPerRoom, projected])

  // useEffect(() => {
  //   setRent_month(rent * projected)
  // }, [rent, projected])

  useEffect(() => {
    setVacancy_month(rent_month * (vacancy_month_per / 100))
  }, [rent_month, vacancy_month_per])
  useEffect(() => {
    const newClosingValue = (rent_month * transaction_month_per / 100).toFixed(2);
    setTransaction_month(newClosingValue)
  }, [rent_month, transaction_month_per])
  useEffect(() => {
    setRent_year(rent_month * 12)
  }, [rent_month])

  useEffect(() => {
    // const newClosingValue = (rent_month * transaction_month_per / 100).toFixed(2);
    setGrossRent(rent_month - transaction_month - vacancy_month)
  }, [rent_month, transaction_month, vacancy_month])
  useEffect(() => {
    setTransaction_year(transaction_month * 12)
  }, [transaction_month])
  useEffect(() => {
    setVacancy_year(vacancy_month_per * (rent_year / 100))
  }, [vacancy_month_per, rent_year])
  useEffect(() => {
    setGrossRent_year(rent_year - vacancy_year - transaction_year)
  }, [transaction_year, rent_year, vacancy_year])
  useEffect(() => {
    setLoans_value(Array.from({ length: loancounter }, () => ({
      loanValue: 50,
      interest: 8.75,
      term: 360,
      loan_amount_total: 85034.97,
    })));
    a.setLoans_value(Array.from({ length: loancounter }, () => ({
      loanValue: 50,
      interest: 8.75,
      term: 360,
      loan_amount_total: 85034.97,
    })));
    console.log(loans_value)
  }, [loancounter]);
  //console.log(loans_value)
  useEffect(() => {
    const I24 = blended; // Example annual interest rate (5%)
    const I25 = mainterm; // Example number of payments (60 months)
    const I26 = mainLoanAmount; // Example loan amount ($10,000)
    console.log("_____________blended", blended, '__________________________mainterm', mainterm, '___________________mainLoanAmount', mainLoanAmount)
    const monthlyInterestRate = I24 / 100 / 12;
    const numberOfPayments = I25;
    const loanAmount = I26;

    const payment = calculatePMT(monthlyInterestRate, numberOfPayments, loanAmount);
    setDebettt((payment).toFixed(2))
    console.log(`Monthly Payment: $${debttt}`);
    seYearly_debt(12 * debttt)

  }, [blended, mainterm, mainLoanAmount, cashFlow, CashFlow_year, mainEquity]);
  useEffect(() => {
    setCashFlow_year((net_rent_after_exp_year - (-yearly_debt)).toFixed(2))
    let a = Number(net_rent_after_exp) - (-debttt)
    setCashFlow(a.toFixed(2))
    setLeveraged((CashFlow_year / mainEquity).toFixed(2))
  }, [net_rent_after_exp_year, yearly_debt, net_rent_after_exp, CashFlow_year, mainEquity])


  const lonanlenthcounter = {
    1: 'First',
    2: "Second",
    3: "Third"
  }
  return (
    <div className="mt-5">
      <h1 className="padsplit text-center">Padsplit Rental Model</h1>
      <div className="text-end mx-sm-5">
        {/* <Link className="padsplitLink mx-2">Padsplit</Link>
                <Link className="padsplitAnywhere mx-2">Padsplit Anywhere</Link> */}

        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="Padsplit" title="Padsplit">
            <Container className="mt-4 text-start">
              <h5>
                <label>Price</label>
              </h5>
              <input
                type="text"
                className="w-100 px-2 py-3 priceinput"
                onChange={(e) => setPrice(e.target.value)}
                value={price}
              />

              <div className="closingcosts mt-5">
                <Table className="w-100 closingtable m-0" responsive>
                  <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                    <td className="px-4">
                      <h5
                        style={{
                          background: "transparent",
                          alignSelf: "center",
                          fontWeight: '400'
                        }}
                      >
                        Closing Costs
                        <InformationPopover
                          heading="Closing Costs"
                          data="And here's some amazing content. It's very engaging. right?"
                        />
                      </h5>
                    </td>
                    <td className="px-4">
                      <input
                        className="text-center"
                        style={{
                          width: 'max-content',
                          border: 'none',
                          background: "transparent",
                          alignSelf: "center",
                          fontWeight: '400',
                          outline: 'none'
                        }}
                        value={`${closing_cost}%`}
                        onChange={(e) => {
                          const value = e.target.value.replace('%', '');
                          setClosing_cost(value ? Number(value) : 0);
                          //console.log(Number(value), '-----------------------------')
                        }}
                      />
                      {/* 1.36% */}
                      {/* </h5> */}
                    </td>
                    <td className="px-4">
                      <h5
                        style={{
                          background: "transparent",
                          alignSelf: "center",
                          fontWeight: '400'
                        }}
                        className="text-end"
                      >
                        ${closing_value}
                      </h5>
                    </td>
                  </tr>
                  <tr
                    className="bottomborder"
                    style={{ background: "#1B71AB1A" }}
                  >
                    <td className="py-2 px-4">
                      <h5
                        style={{
                          background: "transparent",
                          alignSelf: "center",
                          fontWeight: '400'
                        }}
                        className="m-0"
                      >
                        Total
                      </h5>
                    </td>
                    <td className="px-4"></td>
                    <td className="px-4">
                      <h5
                        style={{ background: "transparent", fontWeight: '400' }}
                        className="text-end m-0"
                      >
                        ${firstTotal}
                      </h5>
                    </td>
                  </tr>

                </Table>
              </div>

              <div className="mt-4">
                <Row>
                  <Col md='6' className="mt-md-0 mt-xs-4">
                    <h5>
                      <label>Existing Rooms</label>
                    </h5>
                    <input
                      type="text"
                      className="w-100 px-2 py-3 priceinput"
                      value={existing}
                      onChange={(e) => setExisting(e.target.value)}
                    />
                  </Col>
                  <Col md='6' className="mt-md-0 mt-xs-4">
                    <h5>
                      <label>Projected Rooms</label>
                    </h5>
                    <input
                      type="text"
                      className="w-100 px-2 py-3 priceinput"
                      value={projected}
                      onChange={(e) => setProjected(e.target.value)}
                    />
                  </Col>
                </Row>
              </div>

              <div className="closingcosts mt-5">
                <Table className="w-100 closingtable m-0" responsive>
                  <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                    <td className="px-4" >
                      <h5
                        style={{
                          background: "transparent",
                          alignSelf: "center",
                          fontWeight: '400'
                        }}
                      >
                        Conversion Cost Per Room
                        <InformationPopover
                          heading="Conversion Cost Per Room "
                          data="And here's some amazing content. It's very engaging. right?"
                        />
                      </h5>
                    </td>
                    <td className="px-4">
                      <input
                        className="text-center"
                        style={{
                          width: 'max-content',
                          border: 'none',
                          background: "transparent",
                          alignSelf: "center",
                          fontWeight: '400',
                          outline: 'none'
                        }}
                        value={`$${conversionCostPerRoom}`}
                        onChange={(e) => {
                          const value = e.target.value.replace('$', '');
                          setConversionCostPerRoo(value ? Number(value) : 0);
                          //console.log(Number(value), '-----------------------------')
                        }}
                      />
                    </td>
                    <td className="px-4">
                      <h5
                        style={{
                          background: "transparent",
                          alignSelf: "center",
                          fontWeight: '400'
                        }}
                        className="text-end"
                      >
                        ${finalconversionCostPerRoom}
                      </h5>
                    </td>
                  </tr>
                  <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                    <td className="px-4">
                      <h5
                        style={{
                          background: "transparent",
                          alignSelf: "center",
                          fontWeight: '400'
                        }}
                      >
                        Conversion Cost Per Bathroom
                        <InformationPopover
                          heading="Conversion Cost Per Bathroom"
                          data="And here's some amazing content. It's very engaging. right?"
                        />
                      </h5>
                    </td>
                    <td className="px-4">
                      <input
                        className="text-center"
                        style={{
                          width: 'max-content',
                          border: 'none',
                          background: "transparent",
                          alignSelf: "center",
                          fontWeight: '400',
                          outline: 'none'
                        }}
                        value={`$${conversionCostPerBathroom}`}
                        onChange={(e) => {
                          const value = e.target.value.replace('$', '');
                          setConversionCostPerBathroom(value ? Number(value) : 0);
                          //console.log(Number(value), '-----------------------------')
                        }}
                      />
                    </td>
                    <td className="px-4">
                      <h5
                        style={{
                          background: "transparent",
                          alignSelf: "center",
                          fontWeight: '400'
                        }}
                        className="text-end"
                      >
                        ${conversionCostPerBathroom}
                      </h5>
                    </td>
                  </tr>
                  <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                    <td className="px-4">
                      <h5
                        style={{
                          background: "transparent",
                          alignSelf: "center",
                          fontWeight: '400'
                        }}
                      >
                        Furnishing Per Room
                        <InformationPopover
                          heading="Closing Costs"
                          data="And here's some amazing content. It's very engaging. right?"
                        />
                      </h5>
                    </td>
                    <td className="px-4">
                      <input
                        className="text-center"
                        style={{
                          width: 'max-content',
                          border: 'none',
                          background: "transparent",
                          alignSelf: "center",
                          fontWeight: '400',
                          outline: 'none'
                        }}
                        value={`$${conversionFurnishingPerRoom}`}
                        onChange={(e) => {
                          const value = e.target.value.replace('$', '');
                          setConversionFurnishingPerRoo(value ? Number(value) : 0);
                          //console.log(Number(value), '-----------------------------')
                        }}
                      />
                    </td>
                    <td className="px-4">
                      <h5
                        style={{
                          background: "transparent",
                          alignSelf: "center",
                          fontWeight: '400'
                        }}
                        className="text-end"
                      >
                        ${finalconversionFurnishingPerRoom}
                      </h5>
                    </td>
                  </tr>
                  <tr
                    className="bottomborder"
                    style={{ background: "#1B71AB1A" }}
                  >
                    <td className="py-2 px-4">
                      <h5
                        style={{
                          background: "transparent",
                          alignSelf: "center",
                          fontWeight: '400'
                        }}
                        className="m-0"
                      >
                        All in Cost
                      </h5>
                    </td>
                    <td className="px-4"></td>
                    <td className="px-4">
                      <h5
                        style={{ background: "transparent", fontWeight: '400' }}
                        className="text-end m-0"
                      >
                        ${(Number(firstTotal) + Number(finalconversionCostPerRoom) + Number(conversionCostPerBathroom) + Number(finalconversionFurnishingPerRoom))}
                      </h5>
                    </td>
                  </tr>

                </Table>
              </div>
              <div className="my-5">
                <h2 style={{ fontWeight: "600", letterSpacing: "-2" }}>
                  <label>Rent</label>
                </h2>
                <input
                  type="text"
                  className="w-100 px-2 py-3 priceinput"
                  onChange={(e) => setRent(e.target.value)}
                  value={rent}
                />

                <div className="closingcosts mt-5">

                  <Tabs
                    defaultActiveKey="Monthly"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="Monthly" title="Monthly">
                      <div className="closingcosts mt-5">
                        <Table className="w-100 closingtable m-0" responsive>
                          <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                            <td className="px-4" >
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                Rent per month / per room
                                <InformationPopover
                                  heading="Rent"
                                  data="And here's some amazing content. It's very engaging. right?"
                                />
                              </h5>
                            </td>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                {/* 1.36% */}
                              </h5>
                            </td>
                            <td className="px-4">
                              <input
                                className="text-end"
                                style={{
                                  width: 'max-content',
                                  border: 'none',
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400',
                                  outline: 'none'
                                }}
                                value={rent}
                                onChange={(e) => setRent(e.target.value)}
                              />
                            </td>
                          </tr>
                          <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                            <td className="px-4" >
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                Rent
                                <InformationPopover
                                  heading="Rent"
                                  data="And here's some amazing content. It's very engaging. right?"
                                />
                              </h5>
                            </td>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                {/* 1.36% */}
                              </h5>
                            </td>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="text-end"
                              >
                                ${rent_month}
                              </h5>
                            </td>
                          </tr>
                          <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                Vacancy
                                <InformationPopover
                                  heading="Vacancy"
                                  data="And here's some amazing content. It's very engaging. right?"
                                />
                              </h5>
                            </td>
                            <td className="px-4">

                              <input
                                className="text-center"
                                style={{
                                  width: 'max-content',
                                  border: 'none',
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400',
                                  outline: 'none'
                                }}
                                value={`${vacancy_month_per}%`}
                                onChange={(e) => {
                                  const value = e.target.value.replace('%', '');
                                  setVacancy_month_per(value ? Number(value) : 0);
                                  //console.log(Number(value), '-----------------------------')
                                }}
                              />
                            </td>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="text-end"
                              >
                                ${vacancy_month}
                              </h5>
                            </td>
                          </tr>
                          <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                Transaction Fees
                                <InformationPopover
                                  heading="Transaction Fees"
                                  data="And here's some amazing content. It's very engaging. right?"
                                />
                              </h5>
                            </td>
                            <td className="px-4">
                              {/* let [rent_month, setRent_month] = useState();
  let [vacancy_month_per, setVacancy_month_per] = useState();
  let [vacancy_month, setVacancy_month] = useState();
  let [transaction_month_per, setTransaction_month_per] = useState();
  let [transaction_month, setTransaction_month] = useState(); */}
                              <input
                                className="text-center"
                                style={{
                                  width: 'max-content',
                                  border: 'none',
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400',
                                  outline: 'none'
                                }}
                                value={`${transaction_month_per}%`}
                                onChange={(e) => {
                                  const value = e.target.value.replace('%', '');
                                  setTransaction_month_per(value ? Number(value) : 0);
                                  //console.log(Number(value), '-----------------------------')
                                }}
                              />
                            </td>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="text-end"
                              >
                                ${transaction_month}
                              </h5>
                            </td>
                          </tr>
                          <tr
                            className="bottomborder"
                            style={{ background: "#1B71AB1A" }}
                          >
                            <td className="py-2 px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="m-0"
                              >
                                Gross Rent Before Expenses
                              </h5>
                            </td>
                            <td className="px-4"></td>
                            <td className="px-4">
                              <h5
                                style={{ background: "transparent", fontWeight: '400' }}
                                className="text-end m-0"
                              >
                                ${grossRent}
                              </h5>
                            </td>
                          </tr>

                        </Table>
                      </div>
                    </Tab>
                    <Tab eventKey="Annually" title="Annually">
                      <div className="closingcosts mt-5">
                        <Table className="w-100 closingtable m-0" responsive>
                          <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                            <td className="px-4" >
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                Rent per month / per room
                                <InformationPopover
                                  heading="Rent"
                                  data="And here's some amazing content. It's very engaging. right?"
                                />
                              </h5>
                            </td>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                {/* 1.36% */}
                              </h5>
                            </td>
                            <td className="px-4">
                              <input
                                className="text-end"
                                style={{
                                  width: 'max-content',
                                  border: 'none',
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400',
                                  outline: 'none'
                                }}
                                value={rent * 12}
                              // onChange={(e) => setRent(e.target.value)}
                              />
                            </td>
                          </tr>
                          <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                            <td className="px-4" >
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                Rent
                                <InformationPopover
                                  heading="Rent"
                                  data="And here's some amazing content. It's very engaging. right?"
                                />
                              </h5>
                            </td>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                {/* 1.36% */}
                              </h5>
                            </td>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="text-end"
                              >

                                ${rent_year}
                              </h5>
                            </td>
                          </tr>
                          <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                Vacancy
                                <InformationPopover
                                  heading="Vacancy"
                                  data="And here's some amazing content. It's very engaging. right?"
                                />
                              </h5>
                            </td>
                            <td className="px-4">

                              <input
                                className="text-center"
                                style={{
                                  width: 'max-content',
                                  border: 'none',
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400',
                                  outline: 'none'
                                }}
                                value={`${vacancy_month_per}%`}
                                onChange={(e) => {
                                  const value = e.target.value.replace('%', '');
                                  setVacancy_month_per(value ? Number(value) : 0);
                                  //console.log(Number(value), '-----------------------------')
                                }}
                              />
                            </td>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="text-end"
                              >
                                ${vacancy_year}
                              </h5>
                            </td>
                          </tr>
                          <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                Transaction Fees
                                <InformationPopover
                                  heading="Transaction Fees"
                                  data="And here's some amazing content. It's very engaging. right?"
                                />
                              </h5>
                            </td>
                            <td className="px-4">
                              {/* let [rent_month, setRent_month] = useState();
  let [vacancy_month_per, setVacancy_month_per] = useState();
  let [vacancy_month, setVacancy_month] = useState();
  let [transaction_month_per, setTransaction_month_per] = useState();
  let [transaction_month, setTransaction_month] = useState(); */}
                              <input
                                className="text-center"
                                style={{
                                  width: 'max-content',
                                  border: 'none',
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400',
                                  outline: 'none'
                                }}
                                value={`${transaction_month_per}%`}
                                onChange={(e) => {
                                  const value = e.target.value.replace('%', '');
                                  setTransaction_month_per(value ? Number(value) : 0);
                                  //console.log(Number(value), '-----------------------------')
                                }}
                              />
                            </td>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="text-end"
                              >
                                ${transaction_year}
                              </h5>
                            </td>
                          </tr>
                          <tr
                            className="bottomborder"
                            style={{ background: "#1B71AB1A" }}
                          >
                            <td className="py-2 px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="m-0"
                              >
                                Gross Rent Before Expenses
                              </h5>
                            </td>
                            <td className="px-4"></td>
                            <td className="px-4">
                              <h5
                                style={{ background: "transparent", fontWeight: '400' }}
                                className="text-end m-0"
                              >
                                ${grossRent_year}
                              </h5>
                            </td>
                          </tr>

                        </Table>
                      </div>
                    </Tab>
                  </Tabs>


                </div>
              </div>

              <div className="my-5">
                <div>
                  <h2 style={{ fontWeight: "600", letterSpacing: "-2" }}>
                    <label>Operating Expenses</label>
                  </h2>
                  <Tabs
                    defaultActiveKey="Monthly"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="Monthly" title="Monthly">
                      <div className="closingcosts mt-5">
                        <Table className="w-100 closingtable m-0" responsive>
                          <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                Padsplit Fee
                                <InformationPopover
                                  heading="Rent"
                                  data="And here's some amazing content. It's very engaging. right?"
                                />
                              </h5>
                            </td>
                            <td className="px-4">
                              <input
                                className="text-center"
                                style={{
                                  width: 'max-content',
                                  border: 'none',
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400',
                                  outline: 'none'
                                }}
                                value={`${padsplit_fee_per}%`}
                                onChange={(e) => {
                                  const value = e.target.value.replace('%', '');
                                  setPadsplit_fee_per(value ? Number(value) : 0);
                                  //console.log(Number(value), '-----------------------------')
                                }}
                              />
                            </td>

                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="text-end"
                              >
                                ${padsplit_fee}
                              </h5>
                            </td>
                          </tr>
                          <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                Utilities
                                <InformationPopover
                                  heading="Rent"
                                  data="And here's some amazing content. It's very engaging. right?"
                                />
                              </h5>
                            </td>
                            <td className="px-4">
                              <input
                                className="text-center"
                                style={{
                                  width: 'max-content',
                                  border: 'none',
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400',
                                  outline: 'none'
                                }}
                                value={`${utilities_per}%`}
                                onChange={(e) => {
                                  const value = e.target.value.replace('%', '');
                                  setUtilities_per(value ? Number(value) : 0);
                                  //console.log(Number(value), '-----------------------------')
                                }}
                              />
                            </td>

                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="text-end"
                              >
                                ${utilities}
                              </h5>
                            </td>
                          </tr>
                          <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                Maintenance
                                <InformationPopover
                                  heading="Rent"
                                  data="And here's some amazing content. It's very engaging. right?"
                                />
                              </h5>
                            </td>
                            <td className="px-4">
                              <input
                                className="text-center"
                                style={{
                                  width: 'max-content',
                                  border: 'none',
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400',
                                  outline: 'none'
                                }}
                                value={`${maintenance_per}%`}
                                onChange={(e) => {
                                  const value = e.target.value.replace('%', '');
                                  setMaintenance_per(value ? Number(value) : 0);
                                  //console.log(Number(value), '-----------------------------')
                                }}
                              />
                            </td>

                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="text-end"
                              >
                                ${maintenance}
                              </h5>
                            </td>
                          </tr>
                          <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                Subcontractor
                                <InformationPopover
                                  heading="Rent"
                                  data="And here's some amazing content. It's very engaging. right?"
                                />
                              </h5>
                            </td>
                            <td className="px-4">
                              <input
                                className="text-center"
                                style={{
                                  width: 'max-content',
                                  border: 'none',
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400',
                                  outline: 'none'
                                }}
                                value={`${subcontract_per}%`}
                                onChange={(e) => {
                                  const value = e.target.value.replace('%', '');
                                  setSubcontract_per(value ? Number(value) : 0);
                                  //console.log(Number(value), '-----------------------------')
                                }}
                              />
                            </td>

                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="text-end"
                              >
                                ${subcontract}
                              </h5>
                            </td>
                          </tr>
                          <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                Property Management
                                <InformationPopover
                                  heading="Rent"
                                  data="And here's some amazing content. It's very engaging. right?"
                                />
                              </h5>
                            </td>
                            <td className="px-4">
                              <input
                                className="text-center"
                                style={{
                                  width: 'max-content',
                                  border: 'none',
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400',
                                  outline: 'none'
                                }}
                                value={`${property_managment_per}%`}
                                onChange={(e) => {
                                  const value = e.target.value.replace('%', '');
                                  setProperty_managment_per(value ? Number(value) : 0);
                                  //console.log(Number(value), '-----------------------------')
                                }}
                              />
                            </td>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="text-end"
                              >
                                ${property_managment}
                              </h5>
                            </td>
                          </tr>
                          <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                Insurance
                                <InformationPopover
                                  heading="Rent"
                                  data="And here's some amazing content. It's very engaging. right?"
                                />
                              </h5>
                            </td>
                            <td className="px-4 text-center">
                              <input
                                type="text"
                                style={{
                                  border: "2px solid #1b71abd4",
                                  borderRadius: "5px",
                                  textAlign: 'center',
                                }}
                                value={`${insurance_per}%`}
                                onChange={(e) => {
                                  const value = e.target.value.replace('%', '');
                                  setInsurance_per(value ? Number(value) : 0);
                                  //console.log(Number(value), '-----------------------------')
                                }}
                              />
                            </td>

                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="text-end"
                              >
                                ${insurance}
                              </h5>
                            </td>
                          </tr>
                          <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                Property Tax
                                <InformationPopover
                                  heading="Rent"
                                  data="And here's some amazing content. It's very engaging. right?"
                                />
                              </h5>
                            </td>
                            <td className="px-4 text-center">
                              <input
                                type="text"
                                style={{
                                  border: "2px solid #1b71abd4",
                                  borderRadius: "5px",
                                  textAlign: 'center',
                                }}
                                value={`${property_tax_per}%`}
                                onChange={(e) => {
                                  const value = e.target.value.replace('%', '');
                                  setProperty_tax_per(value ? Number(value) : 0);
                                  //console.log(Number(value), '-----------------------------')
                                }}
                              />
                            </td>

                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="text-end"
                              >
                                ${property_tax}
                              </h5>
                            </td>
                          </tr>
                          <tr
                            className="bottomborder"
                            style={{ background: "#1B71AB1A", borderBottom: '1px solid #60a6d42b' }}
                          >
                            <td className="py-2 px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="m-0"
                              >
                                Total Annual Expense
                              </h5>
                            </td>
                            <td className="py-2 px-4">
                              <h5
                                ca
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="m-0 text-center"
                              >
                                {total_annaual_expense_per} %
                              </h5>
                            </td>

                            <td className="px-4">
                              <h5
                                style={{ background: "transparent", fontWeight: '400' }}
                                className="text-end m-0"
                              >
                                ${total_annaual_expense}
                              </h5>
                            </td>
                          </tr>
                          <tr
                            className="bottomborder"
                            style={{ background: "#1B71AB1A" }}
                          >
                            <td className="py-2 px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="m-0"
                              >
                                Net Rent After Expenses
                              </h5>
                            </td>
                            <td className="py-2 px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="m-0"
                              ></h5>
                            </td>

                            <td className="px-4">
                              <h5
                                style={{ background: "transparent", fontWeight: '400' }}
                                className="text-end m-0"
                              >
                                ${net_rent_after_exp}
                              </h5>
                            </td>
                          </tr>
                        </Table>
                      </div>
                    </Tab>
                    <Tab eventKey="Annually" title="Annually">
                      <div className="closingcosts mt-5">
                        <Table className="w-100 closingtable m-0" responsive>
                          <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                Padsplit Fee
                                <InformationPopover
                                  heading="Rent"
                                  data="And here's some amazing content. It's very engaging. right?"
                                />
                              </h5>
                            </td>
                            <td className="px-4">
                              <input
                                className="text-center"
                                style={{
                                  width: 'max-content',
                                  border: 'none',
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400',
                                  outline: 'none'
                                }}
                                value={`${padsplit_fee_per}%`}
                                onChange={(e) => {
                                  const value = e.target.value.replace('%', '');
                                  setPadsplit_fee_per(value ? Number(value) : 0);
                                  console.log(Number(value), '-----------------------------')
                                }}
                              />
                            </td>

                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="text-end"
                              >
                                ${padsplit_fee_year}
                              </h5>
                            </td>
                          </tr>
                          <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                Utilities
                                <InformationPopover
                                  heading="Rent"
                                  data="And here's some amazing content. It's very engaging. right?"
                                />
                              </h5>
                            </td>
                            <td className="px-4">
                              <input
                                className="text-center"
                                style={{
                                  width: 'max-content',
                                  border: 'none',
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400',
                                  outline: 'none'
                                }}
                                value={`${utilities_per}%`}
                                onChange={(e) => {
                                  const value = e.target.value.replace('%', '');
                                  setUtilities_per(value ? Number(value) : 0);
                                  //console.log(Number(value), '-----------------------------')
                                }}
                              />
                            </td>

                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="text-end"
                              >
                                ${utilities_year}
                              </h5>
                            </td>
                          </tr>
                          <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                Maintenance
                                <InformationPopover
                                  heading="Rent"
                                  data="And here's some amazing content. It's very engaging. right?"
                                />
                              </h5>
                            </td>
                            <td className="px-4">
                              <input
                                className="text-center"
                                style={{
                                  width: 'max-content',
                                  border: 'none',
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400',
                                  outline: 'none'
                                }}
                                value={`${maintenance_per}%`}
                                onChange={(e) => {
                                  const value = e.target.value.replace('%', '');
                                  setMaintenance_per(value ? Number(value) : 0);
                                  //console.log(Number(value), '-----------------------------')
                                }}
                              />
                            </td>

                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="text-end"
                              >
                                ${maintenance_year}
                              </h5>
                            </td>
                          </tr>
                          <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                Subcontractor
                                <InformationPopover
                                  heading="Rent"
                                  data="And here's some amazing content. It's very engaging. right?"
                                />
                              </h5>
                            </td>
                            <td className="px-4">
                              <input
                                className="text-center"
                                style={{
                                  width: 'max-content',
                                  border: 'none',
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400',
                                  outline: 'none'
                                }}
                                value={`${subcontract_per}%`}
                                onChange={(e) => {
                                  const value = e.target.value.replace('%', '');
                                  setSubcontract_per(value ? Number(value) : 0);
                                  //console.log(Number(value), '-----------------------------')
                                }}
                              />
                            </td>

                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="text-end"
                              >
                                ${subcontract_year}
                              </h5>
                            </td>
                          </tr>
                          <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                Property Management
                                <InformationPopover
                                  heading="Rent"
                                  data="And here's some amazing content. It's very engaging. right?"
                                />
                              </h5>
                            </td>
                            <td className="px-4">
                              <input
                                className="text-center"
                                style={{
                                  width: 'max-content',
                                  border: 'none',
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400',
                                  outline: 'none'
                                }}
                                value={`${property_managment_per}%`}
                                onChange={(e) => {
                                  const value = e.target.value.replace('%', '');
                                  setProperty_managment_per(value ? Number(value) : 0);
                                  //console.log(Number(value), '-----------------------------')
                                }}
                              />
                            </td>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="text-end"
                              >
                                ${property_managment_year}
                              </h5>
                            </td>
                          </tr>
                          <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                Insurance
                                <InformationPopover
                                  heading="Rent"
                                  data="And here's some amazing content. It's very engaging. right?"
                                />
                              </h5>
                            </td>
                            <td className="px-4 text-center">
                              <input
                                type="text"
                                style={{
                                  border: "2px solid #1b71abd4",
                                  borderRadius: "5px",
                                  textAlign: 'center',
                                }}
                                value={`${insurance_per}%`}
                                onChange={(e) => {
                                  const value = e.target.value.replace('%', '');
                                  setInsurance_per(value ? Number(value) : 0);
                                  //console.log(Number(value), '-----------------------------')
                                }}
                              />
                            </td>

                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="text-end"
                              >
                                ${insurance_year}
                              </h5>
                            </td>
                          </tr>
                          <tr className="px-5" style={{ borderBottom: '1px solid #60a6d42b' }}>
                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                              >
                                Property Tax
                                <InformationPopover
                                  heading="Rent"
                                  data="And here's some amazing content. It's very engaging. right?"
                                />
                              </h5>
                            </td>
                            <td className="px-4 text-center">
                              <input
                                type="text"
                                style={{
                                  border: "2px solid #1b71abd4",
                                  borderRadius: "5px",
                                  textAlign: 'center',
                                }}
                                value={`${property_tax_per}%`}
                                onChange={(e) => {
                                  const value = e.target.value.replace('%', '');
                                  setProperty_tax_per(value ? Number(value) : 0);
                                  //console.log(Number(value), '-----------------------------')
                                }}
                              />
                            </td>

                            <td className="px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="text-end"
                              >
                                ${property_tax}
                              </h5>
                            </td>
                          </tr>
                          <tr
                            className="bottomborder"
                            style={{ background: "#1B71AB1A", borderBottom: '1px solid #60a6d42b' }}
                          >
                            <td className="py-2 px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="m-0"
                              >
                                Total Annual Expense
                              </h5>
                            </td>
                            <td className="py-2 px-4">
                              <h5
                                ca
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="m-0 text-center"
                              >
                                {total_annaual_expense_per} %
                              </h5>
                            </td>

                            <td className="px-4">
                              <h5
                                style={{ background: "transparent", fontWeight: '400' }}
                                className="text-end m-0"
                              >
                                ${total_annaual_expense_year}
                              </h5>
                            </td>
                          </tr>
                          <tr
                            className="bottomborder"
                            style={{ background: "#1B71AB1A" }}
                          >
                            <td className="py-2 px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="m-0"
                              >
                                Net Rent After Expenses
                              </h5>
                            </td>
                            <td className="py-2 px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="m-0"
                              ></h5>
                            </td>

                            <td className="px-4">
                              <h5
                                style={{ background: "transparent", fontWeight: '400' }}
                                className="text-end m-0"
                              >
                                ${net_rent_after_exp_year}
                              </h5>
                            </td>
                          </tr>
                        </Table>
                      </div>
                    </Tab>
                  </Tabs>
                </div>












                <div className="debt mt-3">
                  <h1>Debt</h1>
                  <div className="text-center">
                    <div className="closingcosts my-3 text-start">
                      <Table className="w-100 closingtable m-0" responsive>
                        <tr
                          className="bottomborder"
                          style={{ background: "#1B71AB1A", borderBottom: '1px solid #60a6d42b' }}
                        >
                          <td className="py-2 px-4">
                            <h5
                              style={{
                                background: "transparent",
                                alignSelf: "center",
                                fontWeight: '400'
                              }}
                              className="m-0"
                            >
                              Debt
                            </h5>
                          </td>

                          <td className="px-4"><h5
                            style={{ background: "transparent", fontWeight: '400' }}
                            className="text-center m-0"
                            onClick={handleCalculate}
                          >
                            ${-1 * debttt}
                          </h5></td>
                          <td className="px-4">
                            <h5
                              style={{ background: "transparent", fontWeight: '400' }}
                              className="text-end m-0"
                            >
                              ${-yearly_debt}
                            </h5>
                          </td>
                        </tr>
                        <tr
                          className="bottomborder"
                          style={{ background: "#1B71AB1A", borderBottom: '1px solid #60a6d42b' }}
                        >
                          <td className="py-2 px-4">
                            <h5
                              style={{
                                background: "transparent",
                                alignSelf: "center",
                                fontWeight: '400'
                              }}
                              className="m-0"
                            >
                              NET Cashflow after Debt
                            </h5>
                          </td>

                          <td className="px-4"><h5
                            style={{ background: "transparent", fontWeight: '400' }}
                            className="text-center m-0"
                            onClick={handleCalculate}
                          >
                            ${cashFlow}
                          </h5></td>
                          <td className="px-4">
                            <h5
                              style={{ background: "transparent", fontWeight: '400' }}
                              className="text-end m-0"
                            >
                              ${CashFlow_year}
                            </h5>
                          </td>
                        </tr>
                        <tr
                          className="bottomborder"
                          style={{ background: "#1B71AB1A", borderBottom: '1px solid #60a6d42b' }}
                        >
                          <td className="py-2 px-4">
                            <h5
                              style={{
                                background: "transparent",
                                alignSelf: "center",
                                fontWeight: '400'
                              }}
                              className="m-0"
                            >
                              Leveraged Cash on Cash Return
                            </h5>
                          </td>
                          <td className="px-4"></td>
                          <td className="px-4">
                            <h5
                              style={{ background: "transparent", fontWeight: '400' }}
                              className="text-end m-0"
                            >
                              {Leveraged * 100}%
                            </h5>
                          </td>
                        </tr>
                        <tr
                          className="bottomborder"
                          style={{ background: "#1B71AB1A" }}
                        >
                          <td className="py-2 px-4">
                            <h5
                              style={{
                                background: "transparent",
                                alignSelf: "center",
                                fontWeight: '400'
                              }}
                              className="m-0"
                            >
                              Yield / Unleveraged Cash on Cash
                            </h5>
                          </td>
                          <td className="px-4"></td>
                          <td className="px-4">
                            <h5
                              style={{ background: "transparent", fontWeight: '400' }}
                              className="text-end m-0"
                            >
                              {yeald}%
                            </h5>
                          </td>
                        </tr>

                      </Table>
                    </div>

                    <div className="forwholesaler">
                      <div>
                        <div className="mt-4">
                          <h1
                            className="text-start"
                            style={{
                              fontWeight: "600",
                              letterSpacing: "-2",
                              alignSelf: "center",
                            }}
                          >
                            <label>For Wholesaler</label>
                          </h1>
                          <Row>
                            <Col md='6'>
                              <h5 className="text-start">
                                <label>Asking Price</label>
                              </h5>
                              <input
                                type="text"
                                className="w-100 px-2 py-3 priceinput"
                                value={Wholesaler}
                                onInput={(e) => setWholesaler(e.target.value)}

                              />
                            </Col>
                            <Col md='6'>
                              <h5 className="text-start">
                                <label>Wholesale Fee</label>
                              </h5>
                              <input
                                type="text"
                                className="w-100 px-2 py-3 priceinput"
                                value={WholesalerFee}
                                onInput={(e) => setWholesalerFee(e.target.value)}
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className="mt-3 p-3" style={{ background: '#1B71AB08', borderRadius: '10px' }}>
                          <div className="d-flex justify-content-between py-2" style={{ borderBottom: '1px solid #60a6d42b' }}>
                            <h5>Price</h5>
                            <h5>${price}</h5>
                          </div>
                          <div className="d-flex justify-content-between py-2">
                            <h5>MAO</h5>
                            <h5>${mao}</h5>
                          </div>
                        </div>
                      </div>
                    </div>





                    {Array.from({ length: loancounter }).map((_, index) => (
                      <div key={index}>
                        <div className="mt-4 d-flex justify-content-between rowtocol">
                          <h1 className="text-start" style={{ fontWeight: "600", letterSpacing: "-2", alignSelf: "center" }}>
                            <label>{lonanlenthcounter[index + 1]} Loan</label>
                          </h1>
                        </div>
                        <div>
                          <div className="loantovalue mt-5">
                            <div className="mt-3">
                              <h5 className="text-start"><label>Loan to Value</label></h5>
                              {/* <input
                                                                type="text"
                                                                style={{
                                                                    border: "2px solid #1b71abd4",
                                                                    borderRadius: "5px",
                                                                    textAlign: 'center',
                                                                }}
                                                                value={`${property_tax_per}%`}
                                                                onChange={(e) => {
                                                                    const value = e.target.value.replace('%', '');
                                                                    setProperty_tax_per(value ? Number(value) : 0);
                                                                    //console.log(Number(value), '-----------------------------')
                                                                }}
                                                            /> */}
                              {/* const handleLoanChange = (index, field, value) => {
        const updatedLoans = loans_value.map((loan, i) =>
            i === index ? { ...loan, [field]: value } : loan
        );
        setLoans_value(updatedLoans);
    }; */}
                              <input
                                type="text"
                                className="w-100 px-2 py-3 priceinput"
                                value={`${loans_value[index]?.loanValue || ''}%`}
                                onInput={(e) => handleLoanChange(index, 'loanValue', e.target.value.replace('%', ''))}
                              />
                            </div>
                            <div className="mt-3">
                              <h5 className="text-start"><label>Interest Rate</label></h5>
                              <input
                                type="text"
                                className="w-100 px-2 py-3 priceinput"
                                value={loans_value[index]?.interest || ''}
                                onInput={(e) => handleLoanChange(index, 'interest', e.target.value)}
                              />
                            </div>
                            <div className="mt-3">
                              <h5 className="text-start"><label>Term</label></h5>
                              <input
                                type="text"
                                className="w-100 px-2 py-3 priceinput"
                                value={loans_value[index]?.term || ''}
                                onInput={(e) => handleLoanChange(index, 'term', e.target.value)}
                              />
                            </div>
                            <div className="py-4 px-4 d-flex justify-content-between mt-4 bottomborder" style={{ background: "#e2edf3" }}>
                              <div>
                                <h5 className="m-0 p-0" style={{ fontWeight: "600" }}>Loan Amount</h5>
                              </div>
                              <div>
                                <h5 className="m-0 p-0" style={{ fontWeight: "600" }}>{loans_value[index]?.loan_amount_total || '85000'}</h5>
                              </div>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="text-end mt-5">
                      {loancounter < 3 &&
                        <Button
                          className="anotherloan px-md-5 py-md-3 text-end"
                          style={{ fontSize: "large" }}
                          onClick={() => {
                            if (loancounter < 3) {
                              setLoancounter(loancounter + 1)
                            }
                          }}
                        >
                          Take another loan
                        </Button>
                      }
                    </div>



                    <div className="mt-5 d-flex justify-content-between rowtocol">
                      <h1
                        className="text-start"
                        style={{
                          fontWeight: "600",
                          letterSpacing: "-2",
                          alignSelf: "center",
                        }}
                      >
                        <label>Total Loans</label>
                      </h1>
                    </div>
                    <div className="loantovalue mt-5">
                      <div className="mt-3">
                        <h5 className="text-start">
                          <label>Loan to Value</label>
                        </h5>

                        {/* <input
                                            type="text"
                                            className="w-100 px-2 py-3 priceinput"
                                            value= {loan_to_value}
                                        /> */}
                        <input
                          type="text"
                          className="w-100 px-2 py-3 priceinput"
                          value={`${loan_to_value}%`}
                          onChange={(e) => {
                            const value = e.target.value.replace('%', '');
                            setLoan_to_value(value ? Number(value) : 0);
                            //console.log(Number(value), '-----------------------------')
                          }}
                        />
                      </div>
                      <div className="mt-3">
                        <h5 className="text-start">
                          <label>Blended Rate</label>
                        </h5>
                        {/* <input
                                            type="text"
                                            className="w-100 px-2 py-3 priceinput"
                                            value={blended}
                                        /> */}
                        <input
                          type="text"
                          className="w-100 px-2 py-3 priceinput"
                          value={`${blended}%`}
                          onChange={(e) => {
                            const value = e.target.value.replace('%', '');
                            setBlended(value ? Number(value) : 0);
                            // handleCalculate()
                            //console.log(Number(value), '-----------------------------')
                          }}
                        />
                      </div>
                      <div className="mt-3">
                        <h5 className="text-start">
                          <label>Term</label>
                        </h5>
                        {/* <input
                                            type="text"
                                            className="w-100 px-2 py-3 priceinput"
                                            value={mainterm}
                                        /> */}
                        <input
                          type="text"
                          className="w-100 px-2 py-3 priceinput"
                          value={`${mainterm}`}
                          onChange={(e) => {
                            const value = e.target.value;
                            setMainterm(value ? Number(value) : 0);
                            //console.log(Number(value), '-----------------------------')
                          }}
                        />
                      </div>
                      <div
                        className="bottomborderboth mt-4"
                        style={{ background: "#e2edf3" }}
                      >
                        <div className="py-4 px-4 d-flex justify-content-between" style={{ borderBottom: '1px solid #60a6d42b' }}>
                          <div>

                            <h5 className="m-0 p-0" style={{ fontWeight: "600" }}>
                              Loan Amount
                            </h5>
                          </div>
                          <div>
                            <h5 className="m-0 p-0" style={{ fontWeight: "600" }}>
                              {mainLoanAmount}
                            </h5>
                          </div>
                        </div>
                        <div className="py-4 px-4 d-flex justify-content-between">
                          <div>

                            <h5 className="m-0 p-0" style={{ fontWeight: "600" }}>
                              Cash Requirement
                            </h5>
                          </div>
                          <div>
                            <h5 className="m-0 p-0" style={{ fontWeight: "600" }}>
                              {mainEquity}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div>

                      </div>
                    </div>
                    <div className='tablediv'>
                      <div className="closingcosts mt-5">
                        <Table className="w-100 closingtable m-0" responsive>
                          <tr>
                            <td colSpan='3' style={{ background: '#1B71AB' }}>
                              <h4 style={{ background: 'transparent', fontWeight: '400' }} className='text-center text-white m-0 py-3'>Blended Rate </h4>
                            </td>
                          </tr>

                          {Array.isArray(loans_value) && loans_value.length > 0 ? (
                            loans_value.map((loan, index) => {
                              // Convert loanValue and interest to numbers to avoid string concatenation
                              const loanValue = parseFloat(loan.loanValue);
                              const interest = parseFloat(loan.interest);

                              // Calculate the sum of loanValue and interest
                              const sum = loanValue + interest;

                              return (
                                <tr key={index} className="bottomborder" style={{ background: "#1B71AB1A" }}>
                                  <td className="py-2 px-4">
                                    <h5
                                      style={{
                                        background: "transparent",
                                        alignSelf: "center",
                                        fontWeight: '400',
                                      }}
                                      className="m-0"
                                    >
                                      {loan.loanValue} %
                                    </h5>
                                  </td>
                                  <td className="px-4">
                                    <h5 style={{ background: "transparent", fontWeight: '400' }} className="text-center m-0">
                                      {loan.interest} %
                                    </h5>
                                  </td>
                                  <td className="px-4">
                                    <h5 style={{ background: "transparent", fontWeight: '400' }} className="text-end m-0">
                                      {sum.toFixed(2)} %
                                    </h5>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="3" className="text-center">No loans available</td>
                            </tr>
                          )}


                          <tr
                            className="bottomborder"
                            style={{ background: "#1B71AB1A" }}
                          >
                            <td className="py-2 px-4">
                              <h5
                                style={{
                                  background: "transparent",
                                  alignSelf: "center",
                                  fontWeight: '400'
                                }}
                                className="m-0"
                              >

                              </h5>
                            </td>
                            <td className="px-4">
                              <h5
                                style={{ background: "transparent", fontWeight: '400' }}
                                className="text-end m-0"
                              >

                              </h5>
                            </td>
                            <td className="px-4">
                              <h5
                                style={{ background: "transparent", fontWeight: '400' }}
                                className="text-end m-0"
                              >
                                {a.product} %
                              </h5>
                            </td>
                          </tr>
                        </Table>
                      </div>
                    </div>

                    <Link to='/Padsplit/Blended'>
                      <Button className="savebtn my-4">
                        Save
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            </Container>
          </Tab>
          <Tab
            eventKey="Padsplit Anywhere"
            className="PadsplitAnywhere"
            title="Padsplit Anywhere"
          >
            Padsplit&nbspAnywhere
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Padsplit;
