import React from 'react'
import { Container } from 'react-bootstrap'

const BgblueQuestion = (props) => {
    return (
        <div>
            <div className='bgbluequestionanswerstolearn py-5'>
                <Container>

                    <h2 className='py-2 text-white mb-5' style={{ borderBottom: '3px solid white', width: 'fit-content' }}>
                        {props.question}
                    </h2>
                    <p className='text-white'>
                        {props.answerp1}<br />
                    </p>
                    <p className='text-white'>
                        {props.answerp2}
                    </p>
                </Container>
            </div>

        </div>
    )
}

export default BgblueQuestion
