import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import logo from '../assets/png.png'
import Offcanvas from 'react-bootstrap/Offcanvas';
import React, { useState } from 'react';
import under_con from '../assets/under_con.svg'
import { Link } from 'react-router-dom';
import  MyVerticallyCenteredModal from './modal'
const Navbarr = (props) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalContent, setModalContent] = useState({});
  const handleShowModal = (content) => {
    setModalContent(content);
    setModalShow(true);
    document.getElementById('offcanvasNavbar-expand-md').classList.remove('show')
    const elements = document.getElementsByClassName('offcanvas-backdrop');
    Array.from(elements).forEach((element) => {
      element.classList.remove('show');
    });
  };

  return (
    <div>
      <Navbar expand="lg" sticky="top" className="navbarrr px-md-5 px-2 py-0 navbar-dark">

        <Navbar.Brand><Link to='/' style={{ color: 'white', textDecoration: 'none' }}><img src={logo} className='mianLogo' /></Link></Navbar.Brand>
        <Navbar.Toggle aria-controls="offcanvasNavbar-expand-md" />
        <Navbar.Offcanvas id="offcanvasNavbar-expand-md" aria-labelledby='offcanvasNavbarLabel-expand-md'>

          <Offcanvas.Header closeButton data-bs-theme="dark">
            <Offcanvas.Title id={'offcanvasNavbarLabel-expand-md'}>
              <Link to='/' style={{ color: 'white', textDecoration: 'none' }}><img src={logo} className='mianLogo' /></Link>
            </Offcanvas.Title>
          </Offcanvas.Header>


          <Nav className="me-auto justify-content-end w-100">
            <Nav.Link href="#home" style={{ display: 'flex', alignItems: 'center' }} className='text-white'><Link to='/' style={{ color: 'white', textDecoration: 'none' }}>Home</Link></Nav.Link>
            <Nav.Link href="#link" className='' style={{ display: 'flex', alignItems: 'center' }}>
                <Button style={{'background':'transparent','border':'none'}} className='p-0' onClick={() => {handleShowModal({ image: under_con })}}>
       About us
      </Button>

 
      </Nav.Link>

            <Nav.Link href="#link" className='' style={{ display: 'flex', alignItems: 'center' }}>
            <Button style={{'background':'transparent','border':'none'}} className='p-0' onClick={() => {handleShowModal({ image: under_con })}}>
            Training Program 
      </Button>

       
            </Nav.Link>
            <Link>
              
                
                <Button style={{'background':'transparent','border':'none','color':'#1B71AB'}} className='calculator py-3 px-5' onClick={() => handleShowModal({data: 'Register Users Only!', Button: 'Register'})} size="lg">
                {props.bigbtn}
      </Button>

       
              
            </Link>
            <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        image={modalContent.image}
        data={modalContent.data}
        Button={modalContent.Button}
      />
          </Nav>
        </Navbar.Offcanvas>

      </Navbar>
    </div>
  )
}

export default Navbarr
