import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import image from '../assets/handhouse.png'
import Button from 'react-bootstrap/Button';

const Contactushome = () => {
  return (
    <div>
      <Container>
        <div className='contactcontent py-3' >
          <Row>
            <Col md='8' style={{display:'flex',alignItems:'center'}}>
              <div className='px-sm-5 px-2'>
                <div className='selection_section'>
                  <h1 className='text-white'>Access the ROI calculator to determine a good or great deal!!!</h1>
                </div>
                <Button className='calcon mt-5' size="lg">
                  Go to Calculator
                </Button>
              </div>
            </Col>
            <Col md='4' className='text-md-end text-center'>
              <img src={image} className='contact_us_home_image' />
            </Col>

          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Contactushome
