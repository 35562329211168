import React, { useContext } from 'react'
import Navbarr from './Navbar'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Table from "react-bootstrap/Table";
import loadContext from '../context/loadContext';
const Blended = () => {
    const a = useContext(loadContext)
    return (

        <div>
            <Navbarr bigbtn="Contact Us" />
            <div className='my-5 '>
                <Container>
                    <div className='d-flex justify-content-between rowcolumn'>

                        <Link className='learn' to='/Padsplit' style={{ textDecoration: 'none', fontSize: 'x-large' }}><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                        </svg>&nbsp;Back</Link>
                        <div className='addloaddiv'>
                            <button className='addloanbtn mx-2'>For Wholesaler</button>
                            <button className='addloanbtn mx-2'>Add Loan</button>
                        </div>
                    </div>

                    <div className='tablediv'>
                        <div className="closingcosts mt-5">
                            <Table className="w-100 closingtable m-0" responsive>
                                <tr>
                                    <td colSpan='3' style={{ background: '#1B71AB' }}>
                                        <h4 style={{ background: 'transparent', fontWeight: '400' }} className='text-center text-white m-0 py-3'>Blended Rate </h4>
                                    </td>
                                </tr>

                                {a.loans_value.map((loan, index) => {
                                    // Convert loanValue and interest to numbers to avoid string concatenation
                                    const loanValue = parseFloat(loan.loanValue);
                                    const interest = parseFloat(loan.interest);

                                    // Calculate the sum of loanValue and interest
                                    const sum = loanValue + interest;

                                    return (
                                        <tr key={index} className="bottomborder" style={{ background: "#1B71AB1A" }}>
                                            <td className="py-2 px-4">
                                                <h5
                                                    style={{
                                                        background: "transparent",
                                                        alignSelf: "center",
                                                        fontWeight: '400',
                                                    }}
                                                    className="m-0"
                                                >
                                                    {loan.loanValue} %
                                                </h5>
                                            </td>
                                            <td className="px-4">
                                                <h5 style={{ background: "transparent", fontWeight: '400' }} className="text-center m-0">
                                                    {loan.interest} %
                                                </h5>
                                            </td>
                                            <td className="px-4">
                                                <h5 style={{ background: "transparent", fontWeight: '400' }} className="text-end m-0">
                                                    {sum.toFixed(2)} %
                                                </h5>
                                            </td>
                                        </tr>
                                    );
                                })}


                                <tr
                                    className="bottomborder"
                                    style={{ background: "#1B71AB1A" }}
                                >
                                    <td className="py-2 px-4">
                                        <h5
                                            style={{
                                                background: "transparent",
                                                alignSelf: "center",
                                                fontWeight: '400'
                                            }}
                                            className="m-0"
                                        >

                                        </h5>
                                    </td>
                                    <td className="px-4">
                                        <h5
                                            style={{ background: "transparent", fontWeight: '400' }}
                                            className="text-end m-0"
                                        >

                                        </h5>
                                    </td>
                                    <td className="px-4">
                                        <h5
                                            style={{ background: "transparent", fontWeight: '400' }}
                                            className="text-end m-0"
                                        >
                                            {a.product} %
                                        </h5>
                                    </td>
                                </tr>
                            </Table>
                        </div>
                    </div>
                </Container>
            </div>

        </div>
    )
}

export default Blended
