import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const LearnHow = () => {
    return (
        <div className="Howitwork">
            <Container>
                <HashLink to='/LearnHow/learn#ownandoperate' style={{textDecoration:'none'}}>

                <div className="questionhowtolearn py-4 px-4 my-3">
                    <h1>Learn How To Own And Operate</h1>
                    <div style={{ alignSelf: "center", color: "#1B71AB" }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="36"
                            height="36"
                            fill="currentColor"
                            className="bi bi-arrow-right"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                            />
                        </svg>
                    </div>
                </div>
                </HashLink>
                <HashLink to='/LearnHow/learn#coliving' style={{textDecoration:'none'}}>
                <div className="questionhowtolearn py-4 px-4 my-3">
                    <h1>Learn How  to Sell Co-Living to Investors</h1>
                    <div style={{ alignSelf: "center", color: "#1B71AB" }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="36"
                            height="36"
                            fill="currentColor"
                            className="bi bi-arrow-right"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                            />
                        </svg>
                    </div>
                </div>
                </HashLink>
                
                <HashLink to='/LearnHow/learn#Turnkey' style={{textDecoration:'none'}}>
                <div className="questionhowtolearn py-4 px-4 my-3">
                    <h1>Turnkey or Self-manage</h1>
                    <div style={{ alignSelf: "center", color: "#1B71AB" }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="36"
                            height="36"
                            fill="currentColor"
                            className="bi bi-arrow-right"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                            />
                        </svg>
                    </div>
                </div>
                </HashLink>
                <HashLink to='/LearnHow/learn#modules' style={{textDecoration:'none'}}>
                <div className="questionhowtolearn py-4 px-4 my-3">
                    <h1>9 Modules</h1>
                    <div style={{ alignSelf: "center", color: "#1B71AB" }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="36"
                            height="36"
                            fill="currentColor"
                            className="bi bi-arrow-right"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                            />
                        </svg>
                    </div>
                </div>
                </HashLink>
                <HashLink to='/LearnHow/learn#mentoring' style={{textDecoration:'none'}}>
                <div className="questionhowtolearn py-4 px-4 my-3">
                    <h1>Mentoring</h1>
                    <div style={{ alignSelf: "center", color: "#1B71AB" }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="36"
                            height="36"
                            fill="currentColor"
                            className="bi bi-arrow-right"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
                            />
                        </svg>
                    </div>
                </div>
                </HashLink>
                <div className="btns">
                    <Link to={'/Padsplit'} className="text-center my-2">
                    <button className="gobackbtn">Back</button>
                    </Link>
                    <Link to={'/Padsplit'} className="text-center my-2">
                    <button className="calculatorlearn">Go to Calculator</button>
                    </Link>

                </div>
            </Container>
        </div>
    );
};

export default LearnHow;
