import React, { useState, useEffect } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import SwiperCore from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules'; 
import videoimg from '../assets/1.svg'  ;
import ableright from '../assets/ableright.png';
import ableleft from '../assets/ableleft.png';
import disableleft from '../assets/disableleft.png';
import disableright from '../assets/disableright.png';
import s1 from '../assets/s-1.jpeg';
import s2 from '../assets/s-2.jpg';
import s3 from '../assets/s-3.jpg';
import s4 from '../assets/s-4.jpg';
import s5 from '../assets/s-5.jpg';
import s6 from '../assets/s-6.jpg';
import s7 from '../assets/s-7.jpeg';
import s8 from '../assets/s-8.jpeg';
import { Container } from 'react-bootstrap';

// SwiperCore.use([Navigation]);
const Swiperherohome = () => {
  const swiperRef = React.useRef(null);
  const [isLocked, setIsLocked] = useState(false);
  const [preButtonDisabled, setIsPrepreButtonDisabled] = useState(false);
  const [nxtButtonDisabled, setIsNxtButtonDisabled] = useState(false);

  const button = document.querySelector('.heropre');
  const heronxt = document.querySelector('.heronxt');

  useEffect(() => {

    if (document.querySelector('.heropre').classList.contains('swiper-button-disabled')) {
      setIsPrepreButtonDisabled(true);
      //console.log('pre disable')
    } else {
      setIsPrepreButtonDisabled(false);
      //console.log('pre not disable')
    }

    if (document.querySelector('.heronxt').classList.contains('swiper-button-disabled')) {
      setIsNxtButtonDisabled(true);
      //console.log('pre disable')
    } else {
      setIsNxtButtonDisabled(false);
      //console.log('pre not disable')
    }
  }, []);
  const goNext = () => {
    if (swiperRef) {
      if (document.querySelector('.heropre').classList.contains('swiper-button-disabled')) {
        setIsPrepreButtonDisabled(true);
        //console.log('pre disable')
      } else {
        setIsPrepreButtonDisabled(false);
        //console.log('pre not disable')
      }
      if (document.querySelector('.heronxt').classList.contains('swiper-button-disabled')) {
        setIsNxtButtonDisabled(true);
        //console.log('pre disable')
      } else {
        setIsNxtButtonDisabled(false);
        //console.log('pre not disable')
      }
      // swiperRef.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef) {
      // swiperRef.slidePrev();
      if (document.querySelector('.heropre').classList.contains('swiper-button-disabled')) {
        setIsPrepreButtonDisabled(true);
        //console.log('pre disable')
      } else {
        setIsPrepreButtonDisabled(false);
        //console.log('pre not disable')
      }
      if (document.querySelector('.heronxt').classList.contains('swiper-button-disabled')) {
        setIsNxtButtonDisabled(true);
        //console.log('pre disable')
      } else {
        setIsNxtButtonDisabled(false);
        //console.log('pre not disable')
      }
    }
  };
  return (
    <div>
      <div className='posetionrel'>
        <div className='posetionabs'>
          <button className="custom-prev heropre" onClick={goPrev} style={{ border: 'none', background: 'transparent', zIndex: '111111111' }}><img className='btnsnxtpre' src={preButtonDisabled ? disableleft : ableleft} style={{ height: '70px' }} /></button>
          <button className="custom-next heronxt" onClick={goNext} style={{ border: 'none', background: 'transparent', zIndex: '111111111' }}><img className='btnsnxtpre' src={nxtButtonDisabled ? disableright : ableright} style={{ height: '70px' }} /></button>
        </div>
        <Container fluid='md'>
          <Swiper pagination={{ clickable: true, }} modules={[Pagination]} className="mySwiper">
            <SwiperSlide><div className='posrel'>
              <img src={s1} style={{ width: '100%' }} />
            </div></SwiperSlide>
            <SwiperSlide><div className='posrel'>
              <img src={s2} style={{ width: '100%' }} />
            </div></SwiperSlide>
            <SwiperSlide><div className='posrel'>
              <img src={s3} style={{ width: '100%' }} />
            </div></SwiperSlide>
            <SwiperSlide><div className='posrel'>
              <img src={s4} style={{ width: '100%' }} />
            </div></SwiperSlide>
            <SwiperSlide><div className='posrel'>
              <img src={s5} style={{ width: '100%' }} />
            </div></SwiperSlide>
            <SwiperSlide><div className='posrel'>
              <img src={s6} style={{ width: '100%' }} />
            </div></SwiperSlide>
            <SwiperSlide><div className='posrel'>
              <img src={s7} style={{ width: '100%' }} />
            </div></SwiperSlide>
            <SwiperSlide><div className='posrel'>
              <img src={s8} style={{ width: '100%' }} />
            </div></SwiperSlide>
          </Swiper>
          {/* </Swiper> */}
        </Container>
      </div>

    </div>
  )
}

export default Swiperherohome
